import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Orgunit } from '@material-ui/icons';
import { history } from '../../../reducers';
import Button from '@material-ui/core/Button';
import { RedirectFunc } from "../../common/CommonFunctions";
import { setActiveOiudConfigs } from "../../../actions/action-config";
import { setActiveOuid, setActiveOuidChain } from "../../../actions/action-permissions";
import configureStore from '../../../store/configureStore';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';

const store = configureStore();

const useStyles = makeStyles({
  root: {
    maxWidth: 280,
    minWidth:280,
  },
  media: {
    height: 140,
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
  }
});

function renderEditButton(props) {
  const { activeOuid, userRoles, policies, activeOuidType } = store.getState().permission;
  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);
  const data = props.Post.data;

  if (activeOuidType === "ROOT") {
    return <Icontooltip title="Edit/Delete">
      <IconButton aria-label="edit/delete" onClick={() => { history.push(`/${activeOuid}/course/${data.identifier}`); }}>
        <EditIcon />
      </IconButton>
    </Icontooltip>
  } else {
    return null
  }
}

export default function CourseCard(props) {
  //console.log("props.Posts.data ", props.Post.data);
  //console.log("props.Posts ", props.Post);

  const classes = useStyles();
  const Icontooltip = withStyles({
    tooltip: {
      fontSize: 12,
    },
  })(Tooltip);
  const data = props.Post.data;

  const { activeOuid } = store.getState().permission;
  const { showGlobalLoader } = store.getState().post;
  return (
    <div>
      {data && (
        <Card className={classes.root} onClick={() => { history.push(`/${activeOuid}/course/${data.identifier}`); }}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={data.featuredImage}
              title=""
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                {data.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {data.summary.substring(0, 110)}...
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={() => { history.push(`/${activeOuid}/course/${data.identifier}`); }}>
              View
            </Button>
            {renderEditButton(props)}
          </CardActions>
        </Card>
      )}
    </div>
  );
}
