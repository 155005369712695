import {
  GET_ALL_WORK_REQUESTED,
  GET_ALL_WORK_SUCCESS,
  GET_ALL_WORK_ERRORED,
  CREATE_OR_UPDATE_WORK_REQUESTED,
  CREATE_OR_UPDATE_WORK_SUCCESS,
  CREATE_OR_UPDATE_WORK_ERRORED,
  GET_WORK_DETAILS_REQUESTED,
  GET_WORK_DETAILS_SUCCESS,
  GET_WORK_DETAILS_ERRORED,
  DELETE_WORK_REQUESTED,
  DELETE_WORK_SUCCESS,
  DELETE_WORK_ERRORED,
  CREATE_OR_UPDATE_WORKLINE_REQUESTED,
  CREATE_OR_UPDATE_WORKLINE_SUCCESS,
  CREATE_OR_UPDATE_WORKLINE_ERRORED,
  GET_ALL_WORKLINE_REQUESTED,
  GET_ALL_WORKLINE_SUCCESS,
  GET_ALL_WORKLINE_ERRORED,
} from "../constant/action-types";
import axios from "axios";
import { showSnackbar } from "./action-snackbar";

import configureStore from "../store/configureStore";

const store = configureStore();

export const create_update_work_requested = () => ({
  type: CREATE_OR_UPDATE_WORK_REQUESTED,
});

export const create_update_work_success = () => ({
  type: CREATE_OR_UPDATE_WORK_SUCCESS,
});

export const create_update_work_errored = (error) => ({
  type: CREATE_OR_UPDATE_WORK_ERRORED,
  error,
});

export const create_update_work =
  (workid, ouid, payload, type) => async (dispatch) => {
    dispatch(create_update_work_requested());
    console.log(`create_update_work`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    const { activeOuidCoreConfigs } = store.getState().config;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url;
      //   console.log(`jwt : ${jwt}`);
      let method = "post";
      let axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
        },
      };

      if (workid) {
        url = `${apiEndpoint}/work/${workid}`;
        if (activeOuidCoreConfigs.data) {
          if (activeOuidCoreConfigs.data.config.entityRules) {
            const entityRules = activeOuidCoreConfigs.data.config.entityRules;
            const enitityRule = entityRules.find(
              ({ entity }) => entity === "work"
            );
            if (enitityRule) {
              const actionsData = enitityRule.publishToApiTopic.actions;
              if (actionsData.indexOf("U") > -1) {
                url = `${apiEndpoint}/work/${workid}?publishToApiTopic=true`;
              }
            }
          }
        }
        method = "put";
      } else {
        url = `${apiEndpoint}/work`;
        if (activeOuidCoreConfigs.data) {
          if (activeOuidCoreConfigs.data.config.entityRules) {
            const entityRules = activeOuidCoreConfigs.data.config.entityRules;
            const enitityRule = entityRules.find(
              ({ entity }) => entity === "work"
            );
            if (enitityRule) {
              const actionsData = enitityRule.publishToApiTopic.actions;
              if (actionsData.indexOf("C") > -1) {
                url = `${apiEndpoint}/work?publishToApiTopic=true`;
              }
            }
          }
        }
      }

      console.log(`url : ${url}`);
      console.log(`payload : ${JSON.stringify(payload)}`);
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Work Create or Upadate Success");
          if (workid) {
            dispatch(showSnackbar("Update Successful", "success"));
          } else {
            dispatch(showSnackbar("Creation Successful", "success"));
          }
          console.log("response : ", response);

          dispatch(create_update_work_success());
          return response.data.identifier;
        })
        .catch((err) => {
          console.log("Work Create or Upadate Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));

          dispatch(showSnackbar("Work Create or Upadate Failed", "error"));
          //dispatch(create_update_work_errored(err));
        });
    }
    return null;
  };

export const get_all_works_requested = () => ({
  type: GET_ALL_WORK_REQUESTED,
});

export const get_all_work_success = (payload) => ({
  type: GET_ALL_WORK_SUCCESS,
  payload,
});

export const get_all_work_errored = (error) => ({
  type: GET_ALL_WORK_ERRORED,
  error,
});

export const get_all_works =
  (ouid, type, teamid, status, username, dt1, dt2) => async (dispatch) => {
    dispatch(get_all_works_requested());
    console.log(`get_all_works`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = "";
      if (ouid && type && !teamid && !status && !username && !dt1 && !dt2) {
        url = `${apiEndpoint}/work?type=${type}`;
      } else if (
        ouid &&
        type &&
        !teamid &&
        status &&
        !username &&
        !dt1 &&
        !dt2
      ) {
        url = `${apiEndpoint}/work?type=${type}&status=${status}`;
      } else if (
        ouid &&
        type &&
        !teamid &&
        status &&
        username &&
        !dt1 &&
        !dt2
      ) {
        url = `${apiEndpoint}/work?type=${type}&status=${status}&username=${username}`;
      } else if (
        ouid &&
        type &&
        teamid &&
        !status &&
        !username &&
        !dt1 &&
        !dt2
      ) {
        url = `${apiEndpoint}/work?type=${type}&teamId=${teamid}`;
      } else if (
        ouid &&
        type &&
        teamid &&
        status &&
        !username &&
        !dt1 &&
        !dt2
      ) {
        url = `${apiEndpoint}/work?type=${type}&teamId=${teamid}&status=${status}`;
      } else if (ouid && type && teamid && status && username && !dt1 && !dt2) {
        url = `${apiEndpoint}/work?type=${type}&teamId=${teamid}&status=${status}&username=${username}`;
      }
      // console.log(`jwt : ${jwt}`);
      // console.log(`url : ${url}`);
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Works Success");
          // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
          dispatch(get_all_work_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Works Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(showSnackbar("Get All Works Failed", "error"));
          dispatch(get_all_work_errored(err));
        });
    }
    return null;
  };

export const get_works =
  (ouid, type, teamid, status, username, pTag, sTag, dt1, dt2) =>
  async (dispatch) => {
    dispatch(get_all_works_requested());
    console.log(`get_works`);
    console.log(`pTag`, pTag);
    console.log(`dt1`, dt1);

    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = "";
      if (
        ouid &&
        type &&
        !teamid &&
        !status &&
        !username &&
        !dt1 &&
        !dt2 &&
        !pTag &&
        !sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}`;
      } else if (
        ouid &&
        type &&
        !teamid &&
        status &&
        !username &&
        !dt1 &&
        !dt2 &&
        !pTag &&
        !sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}&status=${status}`;
      } else if (
        ouid &&
        type &&
        !teamid &&
        status &&
        username &&
        !dt1 &&
        !dt2 &&
        !pTag &&
        !sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}&status=${status}&username=${username}`;
      } else if (
        ouid &&
        type &&
        teamid &&
        !status &&
        !username &&
        !dt1 &&
        !dt2 &&
        !pTag &&
        !sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}&teamId=${teamid}`;
      } else if (
        ouid &&
        type &&
        teamid &&
        status &&
        !username &&
        !dt1 &&
        !dt2 &&
        !pTag &&
        !sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}&teamId=${teamid}&status=${status}`;
      } else if (
        ouid &&
        type &&
        teamid &&
        status &&
        username &&
        !dt1 &&
        !dt2 &&
        !pTag &&
        !sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}&teamId=${teamid}&status=${status}&username=${username}`;
      } else if (
        ouid &&
        type &&
        !teamid &&
        status &&
        !username &&
        !dt1 &&
        !dt2 &&
        pTag &&
        !sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}&status=${status}&primaryTag=${pTag}`;
      } else if (
        ouid &&
        type &&
        !teamid &&
        status &&
        username &&
        !dt1 &&
        !dt2 &&
        pTag &&
        !sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}&status=${status}&username=${username}&primaryTag=${pTag}`;
      } else if (
        ouid &&
        type &&
        !teamid &&
        status &&
        !username &&
        !dt1 &&
        !dt2 &&
        !pTag &&
        sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}&status=${status}&secondaryTag=${sTag}`;
      } else if (
        ouid &&
        type &&
        !teamid &&
        status &&
        username &&
        !dt1 &&
        !dt2 &&
        !pTag &&
        sTag
      ) {
        url = `${apiEndpoint}/work?type=${type}&status=${status}&username=${username}&secondaryTag=${sTag}`;
      }

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Works Success");
          // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
          dispatch(get_all_work_success(response.data));
        })
        .catch((err) => {
          console.log("Get All Works Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(showSnackbar("Get All Works Failed", "error"));
          dispatch(get_all_work_errored(err));
        });
    }
    return null;
  };

export const get_works_details_requested = () => ({
  type: GET_WORK_DETAILS_REQUESTED,
});

export const get_works_details_success = (payload) => ({
  type: GET_WORK_DETAILS_SUCCESS,
  payload,
});

export const get_works_details_errored = (error) => ({
  type: GET_WORK_DETAILS_ERRORED,
  error,
});

export const get_works_details = (workid) => async (dispatch) => {
  dispatch(get_works_details_requested());
  console.log(`get_works_details`);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/work/${workid}`;
    //   console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get Work Details Success");
        // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
        dispatch(get_works_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get Work Details Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Get Work Details Failed", "error"));
        dispatch(get_works_details_errored(err));
      });
  }
  return null;
};

export const delete_work_requested = () => ({
  type: DELETE_WORK_REQUESTED,
});

export const delete_work_success = (payload) => ({
  type: DELETE_WORK_SUCCESS,
  payload,
});

export const delete_work_errored = (error) => ({
  type: DELETE_WORK_ERRORED,
  error,
});

export const delete_work = (id) => async (dispatch) => {
  dispatch(delete_work_requested());
  console.log(`delete_work`);
  const { apiEndpoint, credentials } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/work/${id}`;
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      if (activeOuidCoreConfigs.data.config.entityRules) {
        const entityRules = activeOuidCoreConfigs.data.config.entityRules;
        const enitityRule = entityRules.find(
          ({ entity }) => entity === "orgunit"
        );
        if (enitityRule) {
          const actionsData = enitityRule.publishToApiTopic.actions;
          if (actionsData.indexOf("D") > -1) {
            url = `${apiEndpoint}/work/${id}?publishToApiTopic=true`;
          }
        }
      }
    }
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Work Deleted Successfully");
        // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
        dispatch(delete_work_success(response.data));
      })
      .catch((err) => {
        console.log("Delete Work  Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("Delete Work Failed", "error"));
        dispatch(delete_work_errored(err));
      });
  }
  return null;
};

export const create_update_workline_requested = () => ({
  type: CREATE_OR_UPDATE_WORKLINE_REQUESTED,
});

export const create_update_workline_success = () => ({
  type: CREATE_OR_UPDATE_WORKLINE_SUCCESS,
});

export const create_update_workline_errored = (error) => ({
  type: CREATE_OR_UPDATE_WORKLINE_ERRORED,
  error,
});

export const create_update_workline =
  (worklineId, ouid, payload, type) => async (dispatch) => {
    dispatch(create_update_workline_requested());
    console.log("create_update_workline");
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url;
      let method = "post";
      let axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": ouid,
        },
      };
      if (worklineId) {
        method = "put";
        url = `${apiEndpoint}/workline/${worklineId}`;
      } else {
        url = `${apiEndpoint}/workline`;
      }
      // console.log(`url : ${url}`);
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("Workline Create or Upadate Success");
          dispatch(
            showSnackbar("Workline Create or Upadate Successfully", "success")
          );
          dispatch(create_update_workline_success(response.data));
          return response.data.identifier;
        })
        .catch((err) => {
          console.log("Workline Create or Upadate Failed");
          console.error("ErrorLog : ", JSON.stringify(err));
          dispatch(showSnackbar("Workline Create or Upadate Failed", "error"));
          dispatch(create_update_workline_errored(err));
        });
    }
    return null;
  };

export const get_all_workline_requested = () => ({
  type: GET_ALL_WORKLINE_REQUESTED,
});

export const get_all_workline_success = (payload) => ({
  type: GET_ALL_WORKLINE_SUCCESS,
  payload,
});

export const get_all_workline_errored = (error) => ({
  type: GET_ALL_WORKLINE_ERRORED,
  error,
});

export const get_all_workline =
  (activeOuid, workid, type) => async (dispatch) => {
    dispatch(get_all_workline_requested());
    console.log(`get_all_workline`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${apiEndpoint}/workline?workId=${workid}&type=${type}`;
      // console.log(`jwt : ${jwt}`);
      // console.log(`url : ${url}`);
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get All Workline Success");
          // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
          dispatch(get_all_workline_success(response.data));
        })
        .catch((err) => {
          console.log("Get All workline Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get All workline Failed", "error"));
          }
          dispatch(get_all_workline_errored(err));
        });
    }
    return null;
  };
