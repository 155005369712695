import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_team_details, update_team } from '../../../actions/action-team';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import CommonSnackBar from '../../common/CommonSnackBar';
import FormButton from '../../common/FormButton';
import Input from '@material-ui/core/Input';
import { timeFormat } from '../../common/CommonFunctions'


const store = configureStore();

class EditTeamDetails extends Component {

    constructor() {
        super();
        this.state = {
          teamDetails: {},
        };
        this.onInputChange = this.onInputChange.bind(this);
      }
    
      componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
          const path = window.location.pathname;
          history.push(`/login?redirect=${path}`);
          return
      }
        this.fetchDetails();
      }
    
      componentDidUpdate() {
        console.log("componentDidUpdate");
      }
    
      async fetchDetails() {
        const path = window.location.pathname.split('/');
        const id = path[path.length - 1];
        const activeOuid = path[1];
        const { get_team_details } = this.props;
        const { activeOuidChain } = store.getState().permission;
        await get_team_details(id, activeOuid, activeOuidChain);
        const { teamDetails } = store.getState().team;
        this.setState({
          teamDetails: teamDetails,
        });
      }
  
  onInputChange(event) {
    let newteamDetails = this.state.teamDetails;
    newteamDetails.data[event.target.id] = event.target.value;
    this.setState({
        teamDetails : newteamDetails
    });
  }

  async handleSubmit () {
    // event.preventDefault();
    // console.log("State  : ", this.state);
    const path = window.location.pathname.split('/');
    const id = path[path.length - 1];
    const activeOuid = path[1];
    const { update_team } = this.props;
    await update_team(this.state.teamDetails, id, activeOuid);
    history.push(`/${activeOuid}/team/${id}`);
  }

  
  renderDetails(details, onInputChange) {
    return Object.keys(details).map(function(keyName, keyIndex) {
      return (
             <div key={keyName} className="form-item-wrapper">
            <label htmlFor="Clientid" className="input_label">
                {keyName} {" : "}
                </label>
            {keyName === 'name' || keyName === 'type' || keyName === 'createdDate' || keyName === 'lastModifiedDate' || keyName === 'identifier' || keyName === 'createdBy'    ? 
            keyName === 'lastModifiedDate' || keyName === 'createdDate' ? 
            <Input 
            className="input"
            id={keyName}
            value={timeFormat(details[keyName])}
            readOnly
             />
             :
             <Input 
             className="input"
             id={keyName}
             value={details[keyName]}
             readOnly
              />
            : <Input 
            className="input"
            id={keyName}
            value={details[keyName]}
            onChange={onInputChange}
             />}
             
         </div>
      )
  });
  }

  render() {
    const { showGlobalLoader } = store.getState().team;
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
         <Sidebar 
            auth={this.props.auth}
            permissions={permissions}
            />
        <section className="section">
        {showGlobalLoader ?
          <Loader />
          : <div className="container">
          <h1>Edit Team Details</h1>
          <form>
          {this.state.teamDetails.data ? this.renderDetails(this.state.teamDetails.data, this.onInputChange) : 'No team details Found'}
          </form>
                    <FormButton
                     onClick={() => this.handleSubmit()}
                     text="Update" 
                     />
                    <CommonSnackBar/>
        </div>}
      </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    team: state.team,
    showGlobalLoader : state.team.showGlobalLoader,
    permission: state.permission,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    get_team_details: (id, activeOuid, activeOuidChain) => dispatch(get_team_details(id, activeOuid, activeOuidChain)),
    update_team: (payload, team, activeOuid) => dispatch(update_team(payload, team, activeOuid)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamDetails);

