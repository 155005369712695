import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { create_course } from "../../../actions/action-course";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import Sidebar from "../../layout/Sidebar";
import configureStore from "../../../store/configureStore";
import { Auth } from "aws-amplify";
import FormErrors from "../../FormErrors";
import Input from "@material-ui/core/Input";
import FormButton from "../../common/FormButton";
import Validate from "../../utility/FormValidation";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Loader from "../../common/Loader";
import { makeStyles } from "@material-ui/core/styles";

const store = configureStore();

class CreateCourse extends Component {
  constructor() {
    super();
    let r = (Math.random() + 1).toString(36).substring(7);
    this.state = {
      postDetails: {
        title: "",
        summary: "",
        type: "default",
        category: "",
        featuredImage: "",
      },
      categoriesToshow: ["Developer", "Architect", "Business", "Support"],
      errors: {},
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return;
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {},
    });
  };

  handleSubmit = async (event) => {
    this.clearErrorState();
    const error = Validate(event, this.state);
    const payload = this.state.postDetails;
    if (!payload.type) {
      payload["type"] = "default";
    }
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    } else {
      const { create_course } = this.props;
      await create_course(payload);
      this.props.history.push(`/${ouid}/course-dash`);
    }
  };
  async categoryChange(newValue) {
    const newPostDetails = this.state.postDetails;
    newPostDetails["category"] = newValue;
    console.log("newValue  newValue : ", newValue);
    this.setState({
      postDetails: newPostDetails,
    });
  }

  onInputChange = (event) => {
    const newPostDetails = this.state.postDetails;
    newPostDetails[event.target.id] = event.target.value;
    this.setState({
      postDetails: newPostDetails,
    });
  };

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().permission;
    return (
      <div className="page">
        <Sidebar auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div className="container">
            <Paper className="">
              <div className="formContainer">
                <FormErrors formerrors={this.state.errors} />
                <form>
                  <h1 className="formName">Create a Certification Course</h1>
                  <div className="form-item-wrapper">
                    <Autocomplete
                      id="category"
                      onChange={(event, newValue) => {
                        this.categoryChange(newValue);
                      }}
                      defaultValue={"ChannelPartner"}
                      value={this.state.postDetails.category}
                      options={this.state.categoriesToshow}
                      getOptionLabel={(option) => option}
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Category"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="title"
                      placeholder="Title"
                      value={this.state.postDetails.title}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="summary"
                      placeholder="Summary"
                      value={this.state.postDetails.summary}
                      onChange={this.onInputChange}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </div>
                  <div className="form-item-wrapper">
                    <TextField
                      className="input"
                      id="featuredImage"
                      placeholder="Featured Image"
                      value={this.state.postDetails.featuredImage}
                      onChange={this.onInputChange}
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                </form>
                <div className="field">
                  <p className="control">
                    <FormButton
                      onClick={() => this.handleSubmit()}
                      text="Add"
                    />
                  </p>
                </div>
                <CommonSnackBar />
              </div>
            </Paper>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  create_course: (payload) => dispatch(create_course(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse);
