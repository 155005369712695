import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import Paper from "@material-ui/core/Paper";

import SearchField from "../../../common/SearchField";
import { RedirectFunc } from "../../../common/CommonFunctions";
import { history } from "../../../../reducers";

import { get_tags, create_tag } from "../../../../actions/action-tag";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import { timeFormat } from "../../../common/CommonFunctions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const store = configureStore();
const { activeOuid } = store.getState().permission;

function renderProjects(projects) {
  console.log("projects : ", JSON.stringify(projects));
  return projects.map((row) => (
    <TableRow key={row.data.identifier}>
      <TableCell
        onClick={() => {
          let pathname = history.location.pathname.replace(
            "/projects",
            "/ptickets"
          );
          var search = history.location.search;
          console.log("search", search);

          console.log("pathname", pathname);
          console.log("history.location", history.location);

          const searchParams = new URLSearchParams(search);

          searchParams.set("prj", row.data.identifier);
          searchParams.set("allStatus", "open");
          searchParams.set("myStatus", "assigned");
          searchParams.delete("sprint");

          const searchParamString = searchParams.toString();
          console.log(searchParamString);
          console.log(pathname + "?" + searchParamString);
          history.push(pathname + "?" + searchParamString);
        }}
      >
        {row.data.identifier}
      </TableCell>
      <TableCell>{row.data.createdBy}</TableCell>
      <TableCell>{timeFormat(row.data.createdDate)}</TableCell>
      <TableCell>{row.data.status}</TableCell>
    </TableRow>
  ));
}

function ProjectList(props) {
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const [state, setState] = React.useState({
    projectDetails: {},
    newproject: "",
  });
  const [projects, setProjectsToShow] = React.useState({
    projectsToShow: [],
  });
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    const getProjects = async (activeOuid, activeOuidChain) => {
      let filter = { type: "primaryTag", status: "active" };
      await store.dispatch(get_tags(activeOuid, activeOuidChain, filter));
      console.log(
        "store.getState().tag.primaryTags",
        store.getState().tag.primaryTags
      );
      setProjectsToShow({
        projectsToShow: store.getState().tag.primaryTags,
      });
    };
    getProjects(activeOuid, activeOuidChain);
  }, [activeOuid, activeOuidChain]);

  const handleCreateProject = async (event) => {
    console.log("handleCreateProject");
    console.log("Newproject", state.newproject);
    let payload = {
      identifier: state.newproject,
      type: "primaryTag",
      status: "active",
    };
    await store.dispatch(create_tag(payload));
    const newprojectDetails = state.projectDetails;
    setState({
      projectDetails: newprojectDetails,
      newproject: "",
    });
    let filter = { type: "primaryTag", status: "active" };
    await store.dispatch(get_tags(activeOuid, activeOuidChain, filter));
    setProjectsToShow({
      projectsToShow: store.getState().tag.primaryTags,
    });
    setVisible(!visible);
  };

  const search = async (keyword, projects) => {
    console.log("in search");
    if (keyword) {
      const searchPattern = new RegExp(
        keyword.map((term) => `(?=.*${term})`).join(""),
        "i"
      );
      if (projects) {
        setProjectsToShow({
          projectsToShow: projects.filter((project) =>
            project.data.identifier
              ? project.data.identifier.match(searchPattern)
              : ""
          ),
        });
      }
    } else {
      setProjectsToShow({
        projectsToShow: projects,
      });
    }
  };

  const onNewProjectChange = (event) => {
    console.log("onNewProjectChange");
    const newprojectDetails = state.projectDetails;
    if (event.target.id) {
      newprojectDetails[event.target.id] = event.target.value;
    } else {
      newprojectDetails[event.target.name] = event.target.value;
    }
    setState({
      projectDetails: newprojectDetails,
      newproject: event.target.value,
    });
  };

  return (
    <Paper className="TicketTablePaper">
      <div className="ticketTagHeadingWrap">
        <h3>Projects</h3>
        <div>
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={() => setVisible(!visible)}
          >
            <AddCircleIcon />
          </IconButton>
        </div>
      </div>
      {visible && (
        <div className="ticketCreateProjectForm">
          <TextField
            id="filled-basic"
            label="Enter Project Name"
            value={state.projectDetails ? state.projectDetails.title : ""}
            onChange={onNewProjectChange}
            variant="outlined"
            size="small"
            fullWidth
          />
          <Button
            onClick={() => handleCreateProject()}
            variant="contained"
            size="small"
            color="primary"
          >
            Create
          </Button>
        </div>
      )}
      <div className="ticketProjectSearch">
        <SearchField
          onChange={(e) =>
            search(e.target.value.split(" "), store.getState().tag.primaryTags)
          }
        />
      </div>
      {projects.projectsToShow && (
        <div>
          <div className="List">
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="TableHeader">Name</TableCell>
                    <TableCell className="TableHeader">Created By</TableCell>
                    <TableCell className="TableHeader">Created Date</TableCell>
                    <TableCell className="TableHeader">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects.projectsToShow !== 0
                    ? renderProjects(projects.projectsToShow)
                    : "No Projects Found"}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  tag: state.tag,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
