import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../../store/configureStore';
import Sidebar from '../../../layout/Sidebar';

const store = configureStore();

class SalesPro extends Component {
    render() {
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    <div className="container">
                        <div>
                            <h2>
                                Sales pro
                            </h2>
                        </div>
                    </div>
                </section>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesPro);