const dev = {
  "api": {
    "invokeUrl": "https://l94ws2izwk.execute-api.us-east-1.amazonaws.com/dev"
  },
  "cognito": {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_rZjSpsm25",
    "APP_CLIENT_ID": "3i34qel6uusfst3b1hn7r30jbc"
  }
};

const test = {
  "api": {
    "invokeUrl": "https://csbmksu8s5.execute-api.us-east-1.amazonaws.com/test"
  },
  "cognito": {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_8xRT6X7rJ",
    "APP_CLIENT_ID": "1s2l82uedmf2aasr4p0p3mssv2"
  }
};


const prod = {
  "api": {
    "invokeUrl": "https://ffybd55k1e.execute-api.us-east-1.amazonaws.com/prod"
  },
  "cognito": {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_65XNWTsFt",
    "APP_CLIENT_ID": "4dk93ov17ru5t88ppa0r9eem3m"
  }
};

let config = dev;
if (process.env.REACT_APP_STAGE) {
  switch (process.env.REACT_APP_STAGE.trim()) {
    case "dev":
      config = dev
      break;
    case "test":
      config = test;
      break;
    case "prod":
      config = prod;
      break;
    default:
      config = dev
      break;
  }
}


export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};