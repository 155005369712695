import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";
import Sidebar from "../../layout/Sidebar";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import {
  get_all_documents_by_ouid,
  delete_document,
} from "../../../actions/action-document";

import { timeFormat } from "../../common/CommonFunctions";
import DocumentDetails from "./DocumentDetails";
import Button from "@material-ui/core/Button";
import { downloadFromUrl } from "../../common/CommonFunctions";

import MultiDocumentUploader from "../../common/MultiDocumentUploader";
import Loader from "../../common/Loader";
import ImageFileViewer from "react-file-viewer-1";
import FileViewer from "react-file-viewer";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import axios from "axios";

const store = configureStore();

class DocumentBoard extends Component {
  constructor() {
    super();
    this.state = {
      documentListToShow: [],
      tabValue: 0,
      showDocumentDetails: false,
      currentDocument: {},
      upload: false,
      selectionModel: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    const { get_all_documents_by_ouid } = this.props;
    await get_all_documents_by_ouid(ouid);
    const { documents } = store.getState().document;
    this.setState({
      documentListToShow: documents,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  renderDocument = (item) => {
    switch (item.data.key.split(".").pop()) {
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
      case "png": {
        return (
          <ImageFileViewer
            fileType={item.data.key.split(".").pop()}
            filePath={item.SignedUrl}
          />
        );
      }
      default: {
        return (
          <FileViewer
            fileType={item.data.key.split(".").pop()}
            filePath={item.SignedUrl}
          />
        );
      }
    }
  };

  renderDocuments = (documents) => {
    console.log("documents ", documents);

    return documents.map((item, index) => (
      <div className="ticketsDocumentItem">
        <div>
          <div>{documents.length < 50 ? this.renderDocument(item) : ""}</div>
          <div className="ticketsDocumentItemName">
            <a href={item.SignedUrl}>{item.data.name}</a>
          </div>
        </div>
        <div className="ticketsDocumentItemBtnWrapper">
          <IconButton
            aria-label={item.SignedUrl}
            onClick={() => window.open(item.SignedUrl, "_blank")}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            aria-label="download"
            onClick={() => {
              this.getDownloadUrl(item.data.key, item);
            }}
          >
            <GetAppIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => {
              this.deleteDocument(item);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    ));
  };

  getDownloadUrl = async (key, documentDetails) => {
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    console.log("getDownloadUrl ");

    if (apiEndpoint && credentials) {
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getDownloadSignedUrl=true&file=${key}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", JSON.stringify(response));
          console.log(
            "signed url download : ",
            JSON.stringify(response.data.getURL)
          );
          await downloadFromUrl(
            response.data.getURL,
            documentDetails.data.name
          );
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  deleteDocument = async (documentDetails) => {
    const docId = documentDetails.data.identifier;
    console.log("deleteDocument", docId);
    await store.dispatch(delete_document(docId));
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    this.fetchDetails();
  };

  openDetails(e) {
    console.log("openDetails");
    this.setState({
      showDocumentDetails: true,
      currentDocument: e.row,
      selectionModel: e.row.id,
    });
  }

  handleUploadBox() {
    console.log("handleUploadBox");
    this.setState((prevState) => ({
      upload: !prevState.upload,
    }));
  }

  rerender = () => {
    this.fetchDetails();
  };

  closeDetails() {
    console.log("closeDetails");
    this.setState({
      showDocumentDetails: false,
      currentDocument: {},
    });
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().document;
    const { activeOuid, activeOuidChain } = store.getState().permission;
    return (
      <div className="page">
        <Sidebar auth={this.props.auth} permissions={permissions} />
        <section className="section">
          <div>
            <div>
              <Button
                onClick={() => {
                  this.handleUploadBox();
                }}
                variant="contained"
                color="primary"
              >
                Add Document
              </Button>
              <MultiDocumentUploader
                activeOuid={activeOuid}
                activeOuidChain={activeOuidChain}
                rerender={this.rerender}
                defaultState={this.state.upload}
                handleClose={() => {
                  this.handleUploadBox();
                }}
              />
            </div>
            <br></br>
            {showGlobalLoader ? (
              <Loader />
            ) : this.state.documentListToShow.length !== 0 ? (
              <div className="ticketsDocumentItemWrapper">
                {this.renderDocuments(this.state.documentListToShow)}
              </div>
            ) : (
              "No Documents yet..."
            )}
            <div>
              {this.state.showDocumentDetails && (
                <DocumentDetails
                  documentDetails={this.state.currentDocument}
                  closeDetails={() => {
                    this.closeDetails();
                  }}
                />
              )}
            </div>
            <CommonSnackBar />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  document: state.document,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_documents_by_ouid: (activeOuid) =>
    dispatch(get_all_documents_by_ouid(activeOuid)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentBoard);
