import React, { Component } from "react";
import banner from "../assets/images/bannerimg.png";
import Sidebar from "../layout/Sidebar";
import { connect } from "react-redux";

class Home extends Component {
  render() {
    return (
      <div className="page">
        <Sidebar auth={this.props.auth} />
        <section className="hero is-primary">
          <div className="hero-body">
            <div>
              <h1 className="homeBannerHeading">Probot Business OS</h1>
              <h3 className="homeBannerSubHeading">
                Probot platform allows rapid customisation/enhancements of
                standard probot features and providing industry specific
                solutions.
              </h3>
              <div className="homeFeaturesSection">
                <h2>Platform Features</h2>
                <div className="homeFeaturesCardWrapper">
                  <div>
                    Cloud native platform built using serverless technologies.
                  </div>
                  <div>Multitenancy through and through.</div>
                  <div>
                    Organisataion hierarchy with users and roles, ready to
                    configure and use.
                  </div>
                  <div>
                    Document management in the context of work items or
                    organisation or user out of the box.
                  </div>
                  <div>
                    Teams within organisation hierarchy supports multi location
                    and multi team working models of business.
                  </div>
                </div>
              </div>
              <div className="homeFeaturesSection">
                <h2>Business Applications</h2>
                <div className="homeFeaturesCardWrapper">
                  <div>
                    <div>Issue Tracker</div>Light weight Issue Tracker and
                    Project Managament of Tasks
                  </div>
                  <div>
                    <div>Courses</div>Course videos, practice tests and
                    certifications.
                  </div>
                  <div>
                    <div>Posts</div> Posts for content management of various
                    flavours.
                  </div>
                  <div>
                    <div>HRM</div>release pending.
                  </div>
                  <div>
                    <div>Accounting</div>Release pending.
                  </div>
                  <div>
                    <div>Sales Pro</div>Lead generation and management - release
                    pending.
                  </div>
                  <div>
                    <div>E Commerce Process Suite</div>Products, Suppliers,
                    Customers, Orders, Payments the eternal play - release
                    pending.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
