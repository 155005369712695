import {
  REGISTER_Orgunit_REQUESTED,
  REGISTER_Orgunit_SUCCESS,
  REGISTER_Orgunit_ERRORED,
  GET_ALL_Orgunit_REQUESTED,
  GET_ALL_Orgunit_SUCCESS,
  GET_ALL_Orgunit_ERRORED,
  GET_Orgunit_DETAILS_REQUESTED,
  GET_Orgunit_DETAILS_SUCCESS,
  GET_Orgunit_DETAILS_ERRORED,
  UPDATE_Orgunit_DETAILS_REQUESTED,
  UPDATE_Orgunit_DETAILS_SUCCESS,
  UPDATE_Orgunit_DETAILS_ERRORED,
  DELETE_Orgunit_REQUESTED,
  DELETE_Orgunit_SUCCESS,
  DELETE_Orgunit_ERRORED,
  GET_ORG_PLAN_DETAILS_REQUESTED,
  GET_ORG_PLAN_DETAILS_SUCCESS,
  GET_ORG_PLAN_DETAILS_ERRORED
} from '../constant/action-types';
import axios from 'axios';
import { showSnackbar } from './action-snackbar';

import configureStore from '../store/configureStore';

const store = configureStore();

export const register_Orgunit_requested = () => ({
  type: REGISTER_Orgunit_REQUESTED,
});

export const register_Orgunit_success = () => ({
  type: REGISTER_Orgunit_SUCCESS,
});

export const register_Orgunit_errored = (error) => ({
  type: REGISTER_Orgunit_ERRORED,
  error,
});


export const get_all_Orgunit_requested = () => ({
  type: GET_ALL_Orgunit_REQUESTED,
});

export const get_all_Orgunit_success = (payload) => ({
  type: GET_ALL_Orgunit_SUCCESS,
  payload
});

export const get_all_Orgunit_errored = (error) => ({
  type: GET_ALL_Orgunit_ERRORED,
  error,
});

export const get_orgunit_details_requested = () => ({
  type: GET_Orgunit_DETAILS_REQUESTED,
});

export const get_orgunit_details_success = (payload) => ({
  type: GET_Orgunit_DETAILS_SUCCESS,
  payload
});

export const get_orgunit_details_errored = (error) => ({
  type: GET_Orgunit_DETAILS_ERRORED,
  error,
});

export const update_Orgunit_requested = () => ({
  type: UPDATE_Orgunit_DETAILS_REQUESTED,
});

export const update_Orgunit_success = (payload) => ({
  type: UPDATE_Orgunit_DETAILS_SUCCESS,
  payload
});

export const update_Orgunit_errored = (error) => ({
  type: UPDATE_Orgunit_DETAILS_ERRORED,
  error,
});


export const register_Orgunit = (payload, activeOuid, activeOuidChain) => async (dispatch) => {
  dispatch(register_Orgunit_requested());
  console.log(`register_Orgunit`);
  const {
    apiEndpoint,
    credentials
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/orgunit`;
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      if (activeOuidCoreConfigs.data.config.entityRules) {
        const entityRules = activeOuidCoreConfigs.data.config.entityRules;
        const enitityRule = entityRules.find(({ entity }) => entity === 'orgunit');
        if (enitityRule) {
          const actionsData = enitityRule.publishToApiTopic.actions;
          if (actionsData.indexOf('C') > -1) {
            url = `${apiEndpoint}/orgunit?publishToApiTopic=true`;
          }
        }
      }
    }
    // console.log(`jwt : ${jwt}`);
    // console.log('create url', url);
    const method = 'post';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
        'x-ouidchain': activeOuidChain,
      },
    };
    return axios[method](
      url,
      payload,
      axiosHeader,
    )
      .then((response) => {
        console.log("Orgunit Registerd Successfully");
        dispatch(showSnackbar('Orgunit Registerd Successfully', 'success'));
        dispatch(register_Orgunit_success());
      })
      .catch((err) => {
        console.log('Orgunit Registration Failed');
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar('Orgunit Registration Failed', 'error'));
        dispatch(register_Orgunit_errored(err));
      });
  }
  return null;
};

export const update_Orgunit = (payload, activeOuid) => async (dispatch) => {
  dispatch(update_Orgunit_requested());
  console.log(`update_Orgunit`);
  const {
    apiEndpoint,
    credentials
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/orgunit/${activeOuid}`;
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      if (activeOuidCoreConfigs.data.config.entityRules) {
        const entityRules = activeOuidCoreConfigs.data.config.entityRules;
        const enitityRule = entityRules.find(({ entity }) => entity === 'orgunit');
        if (enitityRule) {
          const actionsData = enitityRule.publishToApiTopic.actions;
          if (actionsData.indexOf('C') > -1) {
            url = `${apiEndpoint}/orgunit/${activeOuid}?publishToApiTopic=true`;
          }
        }
      }
    }
    // console.log(`jwt : ${jwt}`);
    // console.log(`create : ${url}`);
    const method = 'put';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](
      url,
      payload,
      axiosHeader,
    )
      .then((response) => {
        console.log("Orgunit Updation Success");
        dispatch(showSnackbar('Orgunit Updated Successfully', 'success'));
        dispatch(update_Orgunit_success());
      })
      .catch((err) => {
        console.log('Orgunit Updation Failed');
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar('Orgunit Updation Failed', 'error'));
        dispatch(update_Orgunit_errored(err));
      });
  }
  return null;
};


export const get_all_Orgunit = (activeOuid) => async (dispatch) => {
  dispatch(get_all_Orgunit_requested());
  console.log(`get_all_Orgunit`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url;
    if (activeOuid) {
      url = `${apiEndpoint}/orgunit?parentOuId=${activeOuid}`;
    } else {
      url = `${apiEndpoint}/orgunit`;
    }
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Get All Orgunit Success");
        // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
        dispatch(get_all_Orgunit_success(response.data));
      })
      .catch((err) => {
        console.log('Get All Orgunit Failed');
        // console.error('ErrorLog : ', JSON.stringify(err));
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, 'error'));
        } else {
          dispatch(showSnackbar('Get All Orgunits Failed', 'error'));
        }
        dispatch(get_all_Orgunit_errored(err));
      });
  }
  return null;
};

export const get_orgunit_details = (activeOuid) => async (dispatch) => {
  dispatch(get_orgunit_details_requested());
  console.log(`get_orgunit_details`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/orgunit/${activeOuid}`;
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Get Orgunit Details Success");
        // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
        dispatch(get_orgunit_details_success(response.data));
      })
      .catch((err) => {
        console.log('Get Orgunit Details  Failed');
        // console.error('ErrorLog : ', JSON.stringify(err));
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, 'error'));
        } else {
          dispatch(showSnackbar('Get Orgunit Details Failed', 'error'));
        }
        dispatch(get_orgunit_details_errored(err));
      });
  }
  return null;
};



export const delete_Orgunit_requested = () => ({
  type: DELETE_Orgunit_REQUESTED,
});

export const delete_Orgunit_success = (payload) => ({
  type: DELETE_Orgunit_SUCCESS,
  payload
});

export const delete_Orgunit_errored = (error) => ({
  type: DELETE_Orgunit_ERRORED,
  error,
});

export const delete_Orgunit = (activeOuid) => async (dispatch) => {
  dispatch(delete_Orgunit_requested());
  console.log(`delete_Orgunit`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/orgunit/${activeOuid}`;
    const { activeOuidCoreConfigs } = store.getState().config;
    if (activeOuidCoreConfigs.data) {
      if (activeOuidCoreConfigs.data.config.entityRules) {
        const entityRules = activeOuidCoreConfigs.data.config.entityRules;
        const enitityRule = entityRules.find(({ entity }) => entity === 'orgunit');
        if (enitityRule) {
          const actionsData = enitityRule.publishToApiTopic.actions;
          if (actionsData.indexOf('D') > -1) {
            url = `${apiEndpoint}/orgunit/${activeOuid}?publishToApiTopic=true`;
          }
        }
      }
    }

    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = 'delete';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Delete Orgunit Success");
        dispatch(showSnackbar('OrgUnit Deletion Requested Successfully', 'success'));
        dispatch(delete_Orgunit_success(response.data));
      })
      .catch((err) => {
        if (err.response) {
          console.log('Delete Orgunit  Failed :', JSON.stringify(err.response.data));
          var data = err.response.data;
          dispatch(showSnackbar(data.error, 'error'));
        } else {
          dispatch(showSnackbar("Delete failed", 'error'));
        }
        dispatch(delete_Orgunit_errored(err));
      });
  }
  return null;
};



export const get_org_plan_details_requested = () => ({
  type: GET_ORG_PLAN_DETAILS_REQUESTED,
});

export const get_org_plan_details_success = (payload) => ({
  type: GET_ORG_PLAN_DETAILS_SUCCESS,
  payload
});

export const get_org_plan_details_errored = (error) => ({
  type: GET_ORG_PLAN_DETAILS_ERRORED,
  error,
});



export const get_org_plan_details = (activeOuid) => async (dispatch) => {
  dispatch(get_org_plan_details_requested());
  console.log(`get_org_plan_details`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/orgplan/${activeOuid}`;
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        'x-ouid': activeOuid,
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Get Org Plan Details Success");
        // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
        dispatch(get_org_plan_details_success(response.data));
      })
      .catch((err) => {
        console.log('Get Org Plan Details  Failed: ', JSON.stringify(err));
        // console.error('ErrorLog : ', JSON.stringify(err));
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, 'error'));
        } else {
          dispatch(showSnackbar('Get Org Plan Details Failed', 'error'));
        }
        dispatch(get_org_plan_details_errored(err));
      });
  }
  return null;
};



