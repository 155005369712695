import {
  CREATE_USER_PROFILE_REQUESTED,
  CREATE_USER_PROFILE_SUCCESS,
  CREATE_USER_PROFILE_ERRORED,
  UPDATE_USER_PROFILE_REQUESTED,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERRORED,
  GET_USER_PROFILE_DETAILS_REQUESTED,
  GET_USER_PROFILE_DETAILS_SUCCESS,
  GET_USER_PROFILE_DETAILS_ERRORED,
  GET_ALL_USER_PROFILES_REQUESTED,
  GET_ALL_USER_PROFILES_SUCCESS,
  GET_ALL_USER_PROFILES_ERRORED,
  DELETE_USER_PROFILE_REQUESTED,
  DELETE_USER_PROFILE_SUCCESS,
  DELETE_USER_PROFILE_ERRORED
} from '../constant/action-types';
import axios from 'axios';
import { showSnackbar } from './action-snackbar';

import configureStore from '../store/configureStore';

const store = configureStore();

export const get_user_profile_details_requested = () => ({
  type: GET_USER_PROFILE_DETAILS_REQUESTED,
});

export const get_user_profile_details_success = (payload) => ({
  type: GET_USER_PROFILE_DETAILS_SUCCESS,
  payload,
});

export const get_user_profile_details_errored = (error) => ({
  type: GET_USER_PROFILE_DETAILS_ERRORED,
  error,
});

export const get_all_user_profiles_requested = () => ({
  type: GET_ALL_USER_PROFILES_REQUESTED,
});

export const get_all_user_profiles_success = (payload) => ({
  type: GET_ALL_USER_PROFILES_SUCCESS,
  payload,
});

export const get_all_user_profiles_errored = (error) => ({
  type: GET_ALL_USER_PROFILES_ERRORED,
  error,
});

export const create_user_profile_requested = () => ({
  type: CREATE_USER_PROFILE_REQUESTED,
});

export const create_user_profile_success = () => ({
  type: CREATE_USER_PROFILE_SUCCESS,
});

export const create_user_profile_errored = (error) => ({
  type: CREATE_USER_PROFILE_ERRORED,
  error,
});

export const update_user_profile_requested = () => ({
  type: UPDATE_USER_PROFILE_REQUESTED,
});

export const update_user_profile_success = () => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
});

export const update_user_profile_errored = (error) => ({
  type: UPDATE_USER_PROFILE_ERRORED,
  error,
});



export const create_user_profile = (payload) => async (dispatch) => {
  dispatch(create_user_profile_requested());
  console.log(`create_user_profile`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/userprofile`;
    // console.log(`jwt : ${jwt}`);
    //   console.log(`url : ${url}`);
    const method = 'post';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](
      url,
      payload,
      axiosHeader,
    )
      .then((response) => {
        console.log('Create User Profile Success');
        dispatch(showSnackbar('User created Successfully', 'success'));
        dispatch(create_user_profile_success(response.data));
      })
      .catch((err) => {
        console.log('Create User Profile Has Errored');
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar('User Creation Failed', 'error'));
        dispatch(create_user_profile_errored(err));
      });
  }
  return null;
};


export const update_user_profile = (payload, username) => async (dispatch) => {
  dispatch(update_user_profile_requested());
  console.log(`update_user_profile`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/userprofile/${username}`;
    // console.log(`jwt : ${jwt}`);
    //   console.log(`url : ${url}`);
    const method = 'put';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](
      url,
      payload,
      axiosHeader,
    )
      .then((response) => {
        console.log('Create User Profile Success');
        dispatch(showSnackbar('User Updated Successfully', 'success'));
        dispatch(update_user_profile_success(response.data));
      })
      .catch((err) => {
        console.log('Create User Profile Has Errored');
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar('User Upadation Failed', 'error'));
        dispatch(update_user_profile_errored(err));
      });
  }
  return null;
};

export const get_all_user_profile_details = (ouid) => async (dispatch) => {
  dispatch(get_all_user_profiles_requested());
  console.log(`get_all_user_profile_details`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url;
    // console.log(`jwt : ${jwt}`);
    const method = 'get';
    let axiosHeader = {};
    if (ouid) {
      url = `${apiEndpoint}/orguser`;
      axiosHeader['headers'] = {
        Authorization: jwt,
        'x-ouid': ouid
      };
    } else {
      url = `${apiEndpoint}/userprofile`;
      axiosHeader['headers'] = {
        Authorization: jwt,
      };
    }
    // console.log(`url : ${url}`);
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log('get all ProfileDetails success');
        dispatch(get_all_user_profiles_success(response.data));
      })
      .catch((err) => {
        console.log('get all ProfileDetails Has Errored');
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(get_all_user_profiles_errored(err));
      });
  }
  return null;
};


export const get_user_profile_details = (username, orgUserCreate) => async (dispatch) => {
  dispatch(get_user_profile_details_requested());
  console.log(`get_profile_details`, orgUserCreate);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url;
    // console.log(`jwt : ${jwt}`);
    //   console.log(`url : ${url}`);
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    if (orgUserCreate) {
      url = `${apiEndpoint}/userprofile?checkUserName=${username}`;
    } else {
      url = `${apiEndpoint}/userprofile/${username}`;
    }
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log('Get Profile Details Success');
        dispatch(get_user_profile_details_success(response.data));
      })
      .catch((err) => {
        console.log('Get Profile Details Has Errored');
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(get_user_profile_details_errored(err));
      });
  }
  return null;
};


export const delete_delete_user_profile_requested = () => ({
  type: DELETE_USER_PROFILE_REQUESTED,
});

export const delete_delete_user_profile_success = (payload) => ({
  type: DELETE_USER_PROFILE_SUCCESS,
  payload
});

export const delete_delete_user_profile_errored = (error) => ({
  type: DELETE_USER_PROFILE_ERRORED,
  error,
});



export const delete_delete_user_profile = (id) => async (dispatch) => {
  dispatch(delete_delete_user_profile_requested());
  console.log(`delete_delete_user_profile`);
  const {
    apiEndpoint,
    credentials,
  } = store.getState().jwt.api;
  if (apiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${apiEndpoint}/userprofile/${id}`;
    // console.log(`jwt : ${jwt}`);
    // console.log(`url : ${url}`);
    const method = 'delete';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
      },
    };
    return axios[method](
      url,
      axiosHeader,
    )
      .then((response) => {
        console.log("Delete User Profile Success");
        // dispatch(showSnackbar('Get All Channel Partner Successfully', 'success'));
        dispatch(delete_delete_user_profile_success(response.data));
      })
      .catch((err) => {
        console.log('Delete User Profile  Failed');
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar('Delete User Profile Failed', 'error'));
        dispatch(delete_delete_user_profile_errored(err));
      });
  }
  return null;
};