import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../reducers";
import CourseCard from "../../pages/Post/CourseCard";
import configureStore from "../../../store/configureStore";
import Sidebar from "../../layout/Sidebar";
import SearchField from "../../common/SearchField";
import CommonSnackBar from "../../common/CommonSnackBar";
import { setActiveOuid } from "../../../actions/action-permissions";
import { get_courses, create_course } from "../../../actions/action-course";
import { DataGrid } from "@material-ui/data-grid";
import { timeFormat } from "../../common/CommonFunctions";
import CommonPopUp from "../../common/CommonPopUp";
import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CertificationImage from "../../assets/images/ibcImages/certificationbanner.png";
import Banner from "../../assets/images/ibcImages/homebanner.png";
import Business from "../../assets/images/ibcImages/buildings.png";

import { Paper } from "@material-ui/core";

const store = configureStore();

class CourseDash extends Component {
  constructor() {
    super();
    this.state = {
      selectionModel: [],
      coursesToShow: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  async fetchDetails() {
    const { activeOuid, activeOuidChain } = store.getState().permission;
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    console.log("path : ", path);
    const { setActiveOuid } = this.props;
    await setActiveOuid(ouid);
    this.setState({
      coursesToShow: [],
    });
    const { get_courses } = this.props;
    await get_courses(activeOuid, activeOuidChain, "", activeOuid);
    console.log(
      "get_courses store.getState().course.courses ",
      store.getState().course
    );
    this.setState({
      coursesToShow: store.getState().course.courses,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  setSelectionModel(selection) {
    this.setState({
      selectionModel: selection,
    });
  }

  renderAddButton() {
    const { activeOuid, userRoles, policies, activeOuidType } =
      store.getState().permission;

    if (activeOuidType === "ROOT") {
      return (
        <Button
          onClick={() => {
            history.push(`/${activeOuid}/create-course`);
          }}
          variant="contained"
          color="primary"
        >
          Add Certification
        </Button>
      );
    } else {
      return null;
    }
  }

  render() {
    const { permissions } = store.getState().permission;
    const { showGlobalLoader } = store.getState().post;
    const { activeOuid, activeOuidChain } = store.getState().permission;

    return (
      <div className="page">
        <div className="container">
          <Sidebar auth={this.props.auth} permissions={permissions} />
          <section className="section">
            <div className="PageBannerWrapper">
              <div>
                <Paper className="PageBanner BannerWithGradient">
                  <div>
                    <h1>Certification programs</h1>
                    <p>Get Certified with latest courses. Learn and grow.</p>
                  </div>
                  <div>
                    <img src={Banner} alt="" />
                  </div>
                </Paper>
              </div>
            </div>
            <div className="">
              {showGlobalLoader ? (
                <Loader />
              ) : (
                <div className="">
                  <div className="listHeader">
                    <div>{this.renderAddButton()}</div>
                  </div>
                  <div>
                    <div className="CourseWrapperflex">
                      {this.state.coursesToShow.length !== 0
                        ? this.renderPosts(this.state.coursesToShow)
                        : "No Courses found..."}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <CommonSnackBar />
          </section>
        </div>
      </div>
    );
  }

  renderPosts(PostList) {
    console.log("PostList : ", JSON.stringify(PostList));
    console.log(
      "this.state.coursesToShow.length : ",
      this.state.coursesToShow.length
    );

    return PostList.map((item) => <CourseCard key={item.title} Post={item} />);
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
  posts: state.post,
});

const mapDispatchToProps = (dispatch) => ({
  get_courses: (activeOuid, activeOuidChain, category, ouid) =>
    dispatch(get_courses(activeOuid, activeOuidChain, category, ouid)),
  setActiveOuid: (activeOuid) => dispatch(setActiveOuid(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDash);
