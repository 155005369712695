import React from "react";
import Sidebar from "../../../layout/Sidebar";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import Suggestion from "../../../assets/images/ibcImages/objects-lightbulb.svg";
import Faq from "../../../assets/images/ibcImages/objects-messages.svg";
import Documentation from "../../../assets/images/ibcImages/objects-manual.svg";
import Knowledge from "../../../assets/images/ibcImages/objects-life-preserver.svg";

const store = configureStore();

export default function HrmAppDash(props) {
  const { investor } = props;
  const { orgunits, showGlobalLoader } = store.getState().orgunit;
  const { permissions, activeOuid } = store.getState().permission;
  return (
    <div className="page">
      <Sidebar auth={props.auth} permissions={permissions} />
      <div className="container">
        <div className="HelpPageWrapper">
          <h1>HRM Dashboard</h1>
          <div className="HelpCategoryWrapper">
            <a href="#" className="HelpCategoryItem">
              <div>
                <img src={Documentation} alt="" />
              </div>
              <div>
                <p className="HelpCategoryItemTitle">Pay Slips</p>
                <p className="HelpCategoryItemText">
                  View and Download Payslips
                </p>
                <div className="btn">View and Download Payslips</div>
              </div>
            </a>
            <a href="#" className="HelpCategoryItem">
              <div>
                <img src={Faq} alt="" />
              </div>
              <div>
                <p className="HelpCategoryItemTitle">Request Leave</p>
                <p className="HelpCategoryItemText">Request Leave</p>
                <div className="btn">Request Leave</div>
              </div>
            </a>
            <a href="#" className="HelpCategoryItem">
              <div>
                <img src={Knowledge} alt="" />
              </div>
              <div>
                <p className="HelpCategoryItemTitle">Attendance</p>
                <p className="HelpCategoryItemText">View Attendance</p>
                <div className="btn">Attendance</div>
              </div>
            </a>
            <a href="#" className="HelpCategoryItem">
              <div>
                <img src={Suggestion} alt="" />
              </div>
              <div>
                <p className="HelpCategoryItemTitle">Employee Details</p>
                <p className="HelpCategoryItemText">Employee Details</p>
                <div className="btn">Employee Details</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
