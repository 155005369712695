import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import configureStore from '../../../store/configureStore';
import FormErrors from "../../FormErrors";
import Input from '@material-ui/core/Input';
import FormButton from '../../common/FormButton';
import { create_update_work } from '../../../actions/action-work';
import { get_all_teamuser } from '../../../actions/action-teamuser';
import { get_all_orguesr } from '../../../actions/action-orguser';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Loader from '../../common/Loader';

const store = configureStore();


class Work extends Component {

    constructor() {
        super();
        this.state = {
            createContext:'',
            workDetails: {},
            usersToshow: [],
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        this.fetchDetails();
    }

    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        const urlParams = new URLSearchParams(window.location.search);
        const teamId = urlParams.get('teamId');
        if (teamId) {
            const { get_all_teamuser } = this.props;
            await get_all_teamuser(ouid, teamId);
            this.setState({
                createContext:"team",
                usersToshow: store.getState().teamUserProfile.teamUserProfiles,
            });
        }else{
            const { get_all_orguesr } = this.props;
            await get_all_orguesr(ouid);
            const { orgUserProfiles } = store.getState().orgUserProfile;
            this.setState({
                createContext:"org",
                usersToshow: orgUserProfiles,
            });
        }

    }

    clearErrorState = () => {
        this.setState({
            errors: {}
        });
    }


    handleSubmit = async event => {
        // event.preventDefault();
        // Form validation
        // this.clearErrorState();
        // const error = Validate(event, this.state);
        // if (error) {
        //   this.setState({
        //     errors: { ...this.state.errors, ...error }
        //   });
        // }
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        const payload = this.state.workDetails;
        const urlParams = new URLSearchParams(window.location.search);
        const teamId = urlParams.get('teamId');
        if(teamId){
            if (teamId !== null) {
                payload['teamId'] = teamId;
            }
            payload['type'] = 'Work';
            const { create_update_work } = this.props;
            await create_update_work('', ouid, payload, '');
            this.props.history.push(`/${ouid}/team/${teamId}/board`);
        }else{
            payload['type'] = 'Work';
            const { create_update_work } = this.props;
            await create_update_work('', ouid, payload, '');
            this.props.history.push(`/${ouid}/org-workqueue-dash`);
        }
      
    }

    onInputChange = event => {
        const newworkDetails = this.state.workDetails;
        newworkDetails[event.target.id] = event.target.value;
        this.setState({
            workDetails: newworkDetails
        });
    }

    userChange(newValue) {
        console.log("newValue  newValue : ", newValue);
        if (newValue) {
            const newworkDetails = this.state.workDetails;
            newworkDetails['username'] = newValue.data.username;
            newworkDetails['userDisplayName'] = newValue.data.name;
            this.setState({
                workDetails: newworkDetails
            });
        } else {
            const newworkDetails = this.state.workDetails;
            newworkDetails['username'] = '';
            this.setState({
                workDetails: newworkDetails
            });
        }
    }


    render() {
        const { permissions } = store.getState().permission;
        const { showGlobalLoader } = store.getState().teamUserProfile;
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    <div className="container">
                        <div className="formContainer">
                            <FormErrors formerrors={this.state.errors} />
                            <form>
                                <h1 className="formName">Work Details</h1>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="title"
                                        placeholder="Work Title"
                                        value={this.state.workDetails.title}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="name"
                                        placeholder="Name"
                                        value={this.state.workDetails.name}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="email"
                                        placeholder="Email Address"
                                        value={this.state.workDetails.email}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="postalCode"
                                        placeholder="Postal Code"
                                        value={this.state.workDetails.postalCode}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="businessCategory"
                                        placeholder="Business category"
                                        value={this.state.workDetails.businessCategory}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="ageOfBusiness"
                                        placeholder="Age of Business"
                                        value={this.state.workDetails.ageOfBusiness}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="district"
                                        placeholder="District"
                                        value={this.state.workDetails.district}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="form-item-wrapper">
                                    <Input
                                        className="input"
                                        id="remark"
                                        placeholder="Remark"
                                        value={this.state.workDetails.remark}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                {showGlobalLoader ?
                                    <Loader />
                                    :
                                    this.state.usersToshow.length !== 0 ?
                                        <div className="form-item-wrapper">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                // value={this.state.selectedCp}
                                                onChange={(event, newValue) => {
                                                    this.userChange(newValue);
                                                }}
                                                options={this.state.usersToshow}
                                                getOptionLabel={(option) => option.data.name}
                                                style={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                            />
                                        </div>
                                        :
                                        "No Users Found....."
                                }
                            </form>
                            <div className="field">
                                <p className="control">
                                    <FormButton
                                        onClick={() => this.handleSubmit()}
                                        text="Add"
                                    />
                                </p>
                            </div>
                            <CommonSnackBar />
                        </div>
                    </div>
                </section>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
    teamUserProfile: state.teamUserProfile,
    orgUserProfile: state.orgUserProfile,
});


const mapDispatchToProps = (dispatch) => ({
    create_update_work: (workid, ouid, payload, type) => dispatch(create_update_work(workid, ouid, payload, type)),
    get_all_teamuser: (ouid, teamId) => dispatch(get_all_teamuser(ouid, teamId)),
    get_all_orguesr: (activeOuid) => dispatch(get_all_orguesr(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Work);
