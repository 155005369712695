import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserCard from '../../common/UserCard';
import { get_all_orguesr } from '../../../actions/action-orguser';
import configureStore from '../../../store/configureStore';
import SearchField from '../../common/SearchField';
import Loader from '../../common/Loader';
import Button from '@material-ui/core/Button';
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { setActiveOuid } from "../../../actions/action-permissions";
import CommonSnackBar from "../../common/CommonSnackBar";


const store = configureStore();

class OrgUsers extends Component {
    constructor() {
        super();
        this.state = {
            usersToShow: '',
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        this.fetchDetails();
    }
    // componentWillUnmount() {
    //     console.log("componentWillUnmount");
    //     this.unregisterHistoryListener()
    // }

    componentDidUpdate() {
        console.log("componentDidUpdate");
    }

    async fetchDetails() {
        const { get_all_orguesr } = this.props;
        const { activeOuid } = store.getState().permission;
        await get_all_orguesr(activeOuid);
        const { orgUserProfiles } = store.getState().orgUserProfile;
        this.setState({
            usersToShow: orgUserProfiles,
        });
    }

    async fetchDetailsBasedOnUrl() {
        console.log("fetchDetailsBasedOnUrl");
        const { activeOuid } = store.getState().permission;
        const path = window.location.pathname.split('/');
        const urlOuid = path[path.length - 2];
        const { get_all_orguesr } = this.props;
        if (urlOuid !== activeOuid) {
            await get_all_orguesr(urlOuid);
            const { orgUserProfiles } = store.getState().orgUserProfile;
            this.setState({
                usersToShow: orgUserProfiles,
            }, () => {
                store.dispatch(setActiveOuid(urlOuid));
            });
        }
    }

    renderUsers(users, activeOuid) {
        console.log("Users : ", JSON.stringify(users));
        return (
                users.map((row) => (
                    <TableRow key={row.data.username}>
                        <TableCell component="th" scope="row">
                            <Button variant="text" onClick={() => { history.push(`/${activeOuid}/orguser/${row.data.username.replace(/ /g, '-')}`); }}>
                                {row.data.name}
                            </Button>
                        </TableCell>
                        <TableCell>{row.data.username}</TableCell>
                        <TableCell>{row.data.email}</TableCell>
                        <TableCell>{row.data.phone_number}</TableCell>
                        <TableCell>{row.data.roles}</TableCell>
                        <TableCell>
                            <Button variant="contained" color="primary" onClick={() => { history.push(`/${activeOuid}/edit-orguser/${row.data.username}`); }}>
                                Edit
                            </Button>
                        </TableCell>

                    </TableRow>
                ))
        );
    }

    search(keyword, users) {
        if (keyword) {
            const searchPattern = new RegExp(keyword.map((term) => `(?=.*${term})`).join(''), 'i');
            if (users) {
                this.setState({
                    usersToShow: users.filter((user) => user.data.name.match(searchPattern)),
                });
            }
        } else {
            this.setState({
                usersToShow: users,
            });
        }
    }

    render() {
        const { orgUserProfiles, showGlobalLoader } = store.getState().orgUserProfile;
        const { activeOuid, permissions } = store.getState().permission;
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">

                    {showGlobalLoader ?
                        <Loader />
                        : <div className="container">
                            <div className="listHeader">
                                <h1>Org Users</h1>
                                <SearchField onChange={(e) => this.search(e.target.value.split(' '), orgUserProfiles)} />
                                <Button onClick={() => { history.push(`/${activeOuid}/create-org-user`); }} variant="contained" color="primary">
                                    Add user to organisation
                                </Button>
                            </div>
                            {this.state.usersToShow &&
                                <div>
                                    <div className="List">
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="TableHeader">Name</TableCell>
                                                        <TableCell className="TableHeader">Username</TableCell>
                                                        <TableCell className="TableHeader">Email</TableCell>
                                                        <TableCell className="TableHeader">Mobile</TableCell>
                                                        <TableCell className="TableHeader">Roles</TableCell>
                                                        <TableCell className="TableHeader"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(this.state.usersToShow.length !== 0) ? this.renderUsers(this.state.usersToShow, activeOuid) : 'No Users Found'}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <CommonSnackBar />
                                </div>
                            }
                        </div>}
                </section>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    orgUserProfile: state.orgUserProfile,
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    get_all_orguesr: (activeOuid) => dispatch(get_all_orguesr(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgUsers);
