import React, { Component } from 'react';
import { connect } from 'react-redux';
import TeamCard from '../../common/TeamCard';
import { get_all_teams } from '../../../actions/action-team';
import configureStore from '../../../store/configureStore';
import SearchField from '../../common/SearchField';
import Loader from '../../common/Loader';
import Button from '@material-ui/core/Button';
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import axios from 'axios';
import { setActiveOuid, setActiveOuidChain } from "../../../actions/action-permissions";
import { setActiveOiudConfigs } from "../../../actions/action-config";
import CommonSnackBar from "../../common/CommonSnackBar";
import { hideSnackbar } from '../../../actions/action-snackbar';


const store = configureStore();

class Team extends Component {
  constructor() {
    super();
    this.state = {
      teamsToShow: '',
    };
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return
    }
    this.unregisterHistoryListener = history.listen((location, action) => {
      // console.log('on route change : ',location);
      // console.log('history ', history);
      this.fetchDetailsBasedOnUrl();
    });
    this.fetchDetails();
  }
  componentWillUnmount(){
    console.log("componentWillUnmount");
    this.unregisterHistoryListener()
    store.dispatch(hideSnackbar());
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
  }

  async fetchDetails() {
    const { get_all_teams } = this.props;
    const { activeOuid } = store.getState().permission;
    await get_all_teams(activeOuid);
    const { teams } = store.getState().team;
    this.setState({
      teamsToShow: teams,
    });
  }
  async fetchDetailsBasedOnUrl() {
    console.log("fetchDetailsBasedOnUrl");
    const path = window.location.pathname.split('/');
    const urlOuid = path[path.length - 2];
    if(path[path.length - 1] === "teams"){
      const { get_all_teams } = this.props;
      await get_all_teams(urlOuid);
      const { teams } = store.getState().team;
      this.setState({
        teamsToShow: teams,
      }, () => {
        store.dispatch(setActiveOuid(urlOuid));
        store.dispatch(setActiveOuidChain(urlOuid));
        store.dispatch(setActiveOiudConfigs(urlOuid));
    });
    }
  }

  renderteam(team) {
    console.log("teams : ", JSON.stringify(team));
    return team.map((item) => (
      <div>
        <TeamCard key={item.identifier}
          team={item}
        />
      </div>
    ));
  }

  search(keyword, teams) {
    if (keyword) {
      const searchPattern = new RegExp(keyword.map((term) => `(?=.*${term})`).join(''), 'i');
      if (teams) {
        this.setState({
          teamsToShow: teams.filter((team) => team.data.name.match(searchPattern)),
        });
      }
    } else {
      this.setState({
        teamsToShow: teams,
      });
    }
  }
  handleChangeStatus({ meta, file }, status){ 
    console.log("status", status, meta, file)
  }
  async handlePut(response,f){
    const url = response.data.uploadURL;
    // console.log('file', f);
    const body = f;
    const method = 'put';
    await axios[method](
      url,
      body
    )
      .then((response) => {
        // console.log("response : ",JSON.stringify(response));
        console.log('Response Upload',response);
        
      })
      .catch((err) => {
        console.error('ErrorLog : ', JSON.stringify(err));
      });
    
  }
  async handleSubmit(files){
    console.log(files) 
    const f = files[0];
    const {
      apiEndpoint,
      credentials,
    } = store.getState().jwt.api;
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${apiEndpoint}/document?signedUrl=true`;
    const method = 'get';
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "Content-type" : "image/jpeg",
      },
    };
  
    const response = await axios[method](
      url,
      axiosHeader,
    )
    const body = f['file'];
    url = response.data.uploadURL;
    const postHeader = {headers: {
      "Content-type" : "image/jpeg",
    }}
    const uploadresponse = axios['put'](
      url,
      body,
      postHeader
    )
    console.log(uploadresponse);
      // .then((response) => {
      //   // console.log("response : ",JSON.stringify(response));
      //   console.log('response',response);
      //   this.handlePut(response, f['file']);
        
      // })
      // .catch((err) => {
      //   console.error('ErrorLog : ', JSON.stringify(err));
      // });

    
  }

  render() {
    const { teams, showGlobalLoader } = store.getState().team;
    const { permissions } = store.getState().permission;
    const { activeOuid } = store.getState().permission;
    return (
      <div className="page">
        <Sidebar
          auth={this.props.auth}
          permissions={permissions}
        />
        <section className="section">
          
          {showGlobalLoader ?
            <Loader />
            : <div className="container">
              <div className="listHeader">
                <h1 className="pageHeading">Teams</h1>
                <SearchField onChange={(e) => this.search(e.target.value.split(' '), teams)} />
                <Button onClick={() => { history.push(`/${activeOuid}/register-team`); }} variant="contained" color="primary">
                  Add new
                </Button>
              </div>
              {this.state.teamsToShow &&
                <div>
                  <div className="List">
                    {(this.state.teamsToShow.length !== 0) ? this.renderteam(this.state.teamsToShow) : 'No OrgUinits Found'}
                  </div>
                </div>
              }
            </div>}
            <CommonSnackBar />
        </section>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  permission: state.permission,
  team: state.team,
});

const mapDispatchToProps = (dispatch) => ({
  get_all_teams: (activeOuid) => dispatch(get_all_teams(activeOuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
