import {
    CREATE_TEAM_USER_REQUESTED,
    CREATE_TEAM_USER_SUCCESS,
    CREATE_TEAM_USER_ERRORED,
    GET_ALL_TEAM_REQUESTED,
    GET_ALL_TEAM_SUCCESS,
    GET_ALL_TEAM_ERRORED,
    GET_TEAM_USER_DETAILS_REQUESTED,
    GET_TEAM_USER_DETAILS_SUCCESS,
    GET_TEAM_USER_DETAILS_ERRORED,
    UPDATE_TEAM_USER_REQUESTED,
    UPDATE_TEAM_USER_SUCCESS,
    UPDATE_TEAM_USER_ERRORED,
} from '../constant/action-types';

const defaultState = {
    showGlobalLoader: false,
    teamUserDetails: {},
    teamUserProfiles: [],
    error: {}
};

export function teamUserProfile(state = defaultState, action) {
    switch (action.type) {

        case CREATE_TEAM_USER_REQUESTED:
            console.log("CREATE_TEAM_USER_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
            };

        case CREATE_TEAM_USER_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
            };

        case CREATE_TEAM_USER_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

        case GET_ALL_TEAM_REQUESTED:
            console.log("GET_ALL_TEAM_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
                teamUserProfiles: []
            };

        case GET_ALL_TEAM_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
                teamUserProfiles: action.payload.items
            };

        case GET_ALL_TEAM_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

        case GET_TEAM_USER_DETAILS_REQUESTED:
            console.log("GET_TEAM_USER_DETAILS_REQUESTED : ");
            return {
                ...state,
                showGlobalLoader: true,
                teamUserDetails: {}
            };

        case GET_TEAM_USER_DETAILS_SUCCESS:
            return {
                ...state,
                showGlobalLoader: false,
                teamUserDetails: action.payload
            };

        case GET_TEAM_USER_DETAILS_ERRORED:
            return {
                ...state,
                showGlobalLoader: false,
                error: action.error
            };

            case UPDATE_TEAM_USER_REQUESTED:
                console.log("UPDATE_TEAM_USER_REQUESTED : ");
                return {
                    ...state,
                    showGlobalLoader: true,
                };
    
            case UPDATE_TEAM_USER_SUCCESS:
                return {
                    ...state,
                    showGlobalLoader: false,
                };
    
            case UPDATE_TEAM_USER_ERRORED:
                return {
                    ...state,
                    showGlobalLoader: false,
                    error: action.error
                };
        default:
            return state;
    }
}