import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../../store/configureStore';
import { get_all_works } from '../../../../actions/action-work';
import CommonSnackBar from "../../../common/CommonSnackBar";
import { setActiveOuid } from "../../../../actions/action-permissions";
import { get_all_orguesr } from '../../../../actions/action-orguser';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MyTasks from './MyTasks';
import AllTasks from './AllTasks';
import Button from '@material-ui/core/Button';
import PopUp from './Common/PopUp';
import { updateQueryStringParameter } from '../../../common/CommonFunctions'

const store = configureStore();

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

class ApprovalDash extends Component {

    constructor() {
        super();
        this.state = {
            currentUser: '',
            appConfig: {},
            workType: "",
            allWorkDefaultStatus: "open",
            myWorkDefaultStatus: "assigned",
            myWorksToshow: [],
            allWorksToshow: [],
            usersToshow: [],
            currentWork: {},
            createWork: false,
            tabValue: 0
        };
    }

    componentDidMount() {
        const { activeOuidConfigs } = store.getState().config;
        const appConfig = activeOuidConfigs.find(({ data }) => data.identifier === 'approvals')
        const urlParams = new URLSearchParams(window.location.search);
        let tab = 0;
        tab = Number(urlParams.get('tab'));
        let allStatus = this.state.allWorkDefaultStatus;
        if (urlParams.get('allStatus')) allStatus = urlParams.get('allStatus');
        let myStatus = this.state.myWorkDefaultStatus;
        if (urlParams.get('myStatus')) myStatus = urlParams.get('myStatus');
        this.setState({
            appConfig: appConfig,
            workType: appConfig.data.config.workItem,
            tabValue: tab,
            allWorkDefaultStatus: allStatus,
            myWorkDefaultStatus: myStatus
        }, async () => {
            this.fetchDetails();
            updateQueryStringParameter('tab', tab, null);
            updateQueryStringParameter('allStatus', allStatus, null);
            updateQueryStringParameter('myStatus', myStatus, null);
        });
    }

    componentWillUnmount() {
        console.log("componentWillUnmount");
        // store.dispatch(hideSnackbar());
    }


    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        await store.dispatch(setActiveOuid(ouid));
        const { session } = store.getState().jwt.api.userSession;
        this.setState({
            currentUser: session.username,
        }, async () => {
            const { get_all_works } = this.props;
            await get_all_works(ouid, this.state.workType, "", this.state.allWorkDefaultStatus, "");
            this.setState({
                allWorksToshow: store.getState().work.works,
            });
            await get_all_works(ouid, this.state.workType, "", this.state.myWorkDefaultStatus, this.state.currentUser);
            this.setState({
                myWorksToshow: store.getState().work.works,
            });
            const { get_all_orguesr } = this.props;
            await get_all_orguesr(ouid);
            this.setState({
                usersToshow: store.getState().orgUserProfile.orgUserProfiles,
            });
        });
    }

    renderWork(works, activeOuid, teamId) {
        let newRows = [];
        works.map((work, index) => {
            let newRow = work.data;
            newRow['id'] = work.data.identifier;
            newRows.push(newRow);
        });
        return newRows;
    }

    async handleAllWorkStatusChange(value) {
        console.log("value :: ", JSON.stringify(value));
        this.setState({
            allWorkDefaultStatus: value
        });
        updateQueryStringParameter('allStatus', value, null);
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        const { get_all_works } = this.props;
        await get_all_works(ouid, this.state.workType, "", value, "");
        this.setState({
            allWorksToshow: store.getState().work.works,
        });
    }

    async handlemyWorkDefaultStatusChange(value) {
        this.setState({
            myWorkDefaultStatus: value
        });
        updateQueryStringParameter('myStatus', value, null);
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        const { get_all_works } = this.props;
        await get_all_works(ouid, this.state.workType, '', value, this.state.currentUser);
        this.setState({
            myWorksToshow: store.getState().work.works,
        });
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        });
        updateQueryStringParameter('tab', newValue, null);
        updateQueryStringParameter('identifier', "", null);
    };

    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    render() {
        const { activeOuid, activeOuidChain } = store.getState().permission
        return (
            <div>
                <Paper>
                    <AppBar position="static">
                        <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="simple tabs example">
                            <Tab label="My Tasks" {...this.a11yProps(0)} />
                            <Tab label="All Tasks" {...this.a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={this.state.tabValue} index={0}>

                        <MyTasks
                            rows={this.renderWork(this.state.myWorksToshow)}
                            usersToshow={this.state.usersToshow}
                            defaultStatus={this.state.myWorkDefaultStatus}
                            handlemyWorkDefaultStatusChange={(e) => { this.handlemyWorkDefaultStatusChange(e) }}
                            workType={this.state.workType}
                        />
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} index={1}>
                        <AllTasks
                            rows={this.renderWork(this.state.allWorksToshow)}
                            usersToshow={this.state.usersToshow}
                            defaultStatus={this.state.allWorkDefaultStatus}
                            handleAllWorkStatusChange={(e) => { this.handleAllWorkStatusChange(e) }}
                            workType={this.state.workType}
                        />
                    </TabPanel>
                </Paper>
                <CommonSnackBar />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
    work: state.work,
    teamUserProfile: state.teamUserProfile,
});

const mapDispatchToProps = (dispatch) => ({
    get_all_orguesr: (activeOuid) => dispatch(get_all_orguesr(activeOuid)),
    get_all_works: (ouid, type, teamId, status, username) => dispatch(get_all_works(ouid, type, teamId, status, username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalDash);