import React, { Component } from 'react';
import { connect } from 'react-redux';
import { create_team_user } from "../../../actions/action-teamuser";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import configureStore from '../../../store/configureStore';
import FormErrors from "../../FormErrors";
import FormButton from '../../common/FormButton';
import { get_all_orguesr } from '../../../actions/action-orguser';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { showSnackbar, hideSnackbar } from '../../../actions/action-snackbar';
import Loader from '../../common/Loader';
import { isEmptyJson } from '../../common/CommonFunctions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Validate from "../../utility/FormValidation";
import { Auth } from "aws-amplify";
import Autocomplete from '@material-ui/lab/Autocomplete';



const store = configureStore();


class CreateTeamUser extends Component {

    constructor() {
        super();
        this.state = {
            usersToshow: [],
            userDetails: {},
            userDetailsExist: {},
            errors: {},
            roles: []
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        this.fetchDetails();
    }

    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const ouid = path[path.length - 3];
        const teamId = path[path.length - 1];
        const { get_all_orguesr } = this.props;
        await get_all_orguesr(ouid);
        this.setState({
            usersToshow: store.getState().orgUserProfile.orgUserProfiles
        });
    }

    componentWillUnmount() {
        store.dispatch(hideSnackbar());
    }

    clearErrorState = () => {
        this.setState({
            errors: {}
        });
    }

    handleSubmit = async event => {
        console.log("handleSubmit");
    }

    userChange = event => {
        console.log("userChange : ", JSON.stringify(event));
        let userData = event.data;
        delete userData.roles;
        delete userData.createdBy;
        delete userData.createdDate;
        this.setState({
            userDetails: userData
        });
    }

    roleChange = event => {
        var newRoles = this.state.roles;
        newRoles[0] = event.target.value;
        this.setState({
            roles: newRoles
        });
    }

    handleSubmit = async event => {
        const path = window.location.pathname.split('/');
        const ouid = path[path.length - 3];
        const teamId = path[path.length - 1];
        // event.preventDefault();
        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        } else {
            this.clearErrorState();
            const roles = this.state.roles;
            if (roles.length !== 0) {
                let userDetails = this.state.userDetails;
                userDetails["roles"] = roles;
                const { create_team_user } = this.props;
                await create_team_user(userDetails, ouid, teamId);
                history.push(`/${ouid}/team/${teamId}/board`);
            }else{
                store.dispatch(showSnackbar('No role selected', 'error'));
            }
        }


    }

    render() {
        const { permissions } = store.getState().permission;
        const { showGlobalLoader } = store.getState().orgUserProfile;
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    <div className="container">
                        <div className="formContainer">
                            <FormErrors formerrors={this.state.errors} />
                            <form>
                                <h1 className="formName">Add User to your team</h1>
                                {showGlobalLoader ?
                                <Loader />
                                :
                                this.state.usersToshow.length !== 0 ?
                                    <div className="form-item-wrapper">
                                        <Autocomplete
                                            id="combo-box-demo"
                                            // value={this.state.selectedCp}
                                            onChange={(event, newValue) => {
                                                this.userChange(newValue);
                                            }}
                                            options={this.state.usersToshow}
                                            getOptionLabel={(option) => option.data.name}
                                            style={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                        />
                                    </div>
                                    :
                                    "No orguser found.....s"
                                }
                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.roles[0]}
                                    onChange={this.roleChange}
                                >
                                    <MenuItem value={"lead"}>Lead</MenuItem>
                                    <MenuItem value={"member"}>Member</MenuItem>
                                </Select>
                            </form>
                            <div className="field">
                                <p className="control">
                                    <FormButton
                                        onClick={() => this.handleSubmit()}
                                        text="Create"
                                    />
                                </p>
                            </div>
                            <CommonSnackBar />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
    orgUserProfile: state.orgUserProfile,

});


const mapDispatchToProps = (dispatch) => ({
    get_all_orguesr: (ouid) => dispatch(get_all_orguesr(ouid)),
    create_team_user: (payload, ouid, teamId) => dispatch(create_team_user(payload, ouid, teamId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeamUser);
