import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import Sidebar from '../../layout/Sidebar';
import { history } from '../../../reducers';
import { get_all_works } from '../../../actions/action-work';
import { get_all_teamuser } from '../../../actions/action-teamuser';
import { get_all_teams } from '../../../actions/action-team';
import CommonSnackBar from "../../common/CommonSnackBar";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { setActiveTeamId } from "../../../actions/action-permissions";
import WorkQueue from '../Work/WorkQueue';
import MyWorkQueue from '../Work/MyWorkQueue';
import TeamUser from './TeamUser';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


const store = configureStore();


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

class TeamBoard extends Component {

    constructor() {
        super();
        this.state = {
            currentUser: '',
            teamsToshow: [],
            activeTeamId: '',
            worksToshow: [],
            teamWorkStatus: "open",
            myWorkStatus: "assigned",
            myWorksToshow: [],
            usersToshow: [],
            currentWork: {},
            workDash: false,
            tabValue: 0,
            detailsViewOpen : false,
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        this.unregisterHistoryListener = history.listen((location, action) => {
            const path = window.location.pathname.split('/');
            if (path[path.length - 1] === "board") {
                this.fetchDetails();
            }
        });
        this.fetchDetails();
    }

    componentWillUnmount() {
        console.log("componentWillUnmount");
        this.unregisterHistoryListener()
        // store.dispatch(hideSnackbar());
    }


    async fetchDetails() {
        const path = window.location.pathname.split('/');
        const ouid = path[path.length - 4];
        const teamId = path[path.length - 2];
        await store.dispatch(setActiveTeamId(teamId));
        const { session } = store.getState().jwt.api.userSession;
        this.setState({
            currentUser: session.username,
        }, async () => {
            const { get_all_teams, get_all_works, get_all_teamuser } = this.props;
            this.setState({
                activeTeamId: teamId
            });
            await get_all_teams(ouid);
            this.setState({
                teamsToshow: store.getState().team.teams,
            });
            await get_all_works(ouid, 'work', teamId, this.state.teamWorkStatus, "");
            this.setState({
                worksToshow: store.getState().work.works,
            });
            await get_all_works(ouid, 'work', teamId, this.state.myWorkStatus, this.state.currentUser);
            this.setState({
                myWorksToshow: store.getState().work.works,
            });
            await get_all_teamuser(ouid, teamId);
            this.setState({
                usersToshow: store.getState().teamUserProfile.teamUserProfiles,
            });
        });
    }

    openWorkDash(data) {
        // console.log("openWorkDash : ", data.row)
        this.setState({
            workDash: true,
            currentWork: data.row
        });
        // console.log("Data : ", JSON.stringify(data))
    }

    renderWork(works, activeOuid, teamId) {
        let newRows = [];
        works.map((work, index) => {
            let newRow = work.data;
            newRow['id'] = work.data.identifier;
            newRows.push(newRow);
        });
        // console.log("newRows   :::: ", newRows);
        return newRows;
    }

    renderTeamTtem(teamsToshow) {
        return teamsToshow.map((item, index) => (
            <MenuItem key={item.data.identifier} value={item.data.identifier}>{item.data.identifier}</MenuItem>
        ));
    };

    async handleTeamChange(value) {
        // console.log("value :: ", JSON.stringify(value));
        this.setState({
            activeTeamId: value
        });
        await store.dispatch(setActiveTeamId(value));
        const path = window.location.pathname.split('/');
        const ouid = path[path.length - 4];
        let url = `/${ouid}/team/${value}/board`;
        history.push(url);
    }

    async handleTeamWorkStatusChange(value) {
        // console.log("value :: ", JSON.stringify(value));
        this.setState({
            teamWorkStatus: value
        });
        const path = window.location.pathname.split('/');
        const ouid = path[path.length - 4];
        const teamId = path[path.length - 2];
        const { get_all_works } = this.props;
        await get_all_works(ouid, 'work', teamId, value, "");
        this.setState({
            worksToshow: store.getState().work.works,
        });
    }

    async handleMyWorkStatusChange(value) {
        // console.log("value :: ", JSON.stringify(value));
        this.setState({
            myWorkStatus: value
        });
        const path = window.location.pathname.split('/');
        const ouid = path[path.length - 4];
        const teamId = path[path.length - 2];
        const { get_all_works } = this.props;
        await get_all_works(ouid, 'work', teamId, value, this.state.currentUser);
        this.setState({
            myWorksToshow: store.getState().work.works,
        });
    }


    handleTabChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        });
    };

    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleDetailsClick(){
        this.setState(prevState => ({
            detailsViewOpen: !prevState.detailsViewOpen
        }));
        
    }


    render() {
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    <div className={`${this.state.detailsViewOpen ? "collapsed" : ""}`}>
                        <div>
                            <FormControl variant="outlined" className="selectBox">
                                <InputLabel id="demo-simple-select-outlined-label">Team</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.activeTeamId}
                                    onChange={event => this.handleTeamChange(event.target.value)}
                                    label="Team"
                                >
                                    {(this.state.teamsToshow.length !== 0) ? this.renderTeamTtem(this.state.teamsToshow)
                                        :
                                        <MenuItem value="">No Team Found</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <Paper>
                            <AppBar position="static">
                                <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="simple tabs example">
                                    <Tab label="My Work Queue" {...this.a11yProps(0)} />
                                    <Tab label="Team Work Queue" {...this.a11yProps(1)} />
                                    <Tab label="Users" {...this.a11yProps(2)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.tabValue} index={0}>
                                <MyWorkQueue
                                    workContext={"team"}
                                    rows={this.renderWork(this.state.myWorksToshow)}
                                    usersToshow={this.state.usersToshow}
                                    defaultStatus={this.state.myWorkStatus}
                                    handleMyWorkStatusChange={(e) => { this.handleMyWorkStatusChange(e) }}
                                    handleDetailsClick={(e) => { this.handleDetailsClick(e) }}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={1}>

                                <WorkQueue
                                    workContext={"team"}
                                    rows={this.renderWork(this.state.worksToshow)}
                                    usersToshow={this.state.usersToshow}
                                    defaultStatus={this.state.teamWorkStatus}
                                    handleTeamWorkStatusChange={(e) => { this.handleTeamWorkStatusChange(e) }}
                                    handleDetailsClick={(e) => { this.handleDetailsClick(e) }}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={2}>
                                <TeamUser
                                    usersToshow={this.state.usersToshow}
                                />
                            </TabPanel>
                        </Paper>

                        <CommonSnackBar />
                    </div>
                </section>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
    work: state.work,
    teamUserProfile: state.teamUserProfile,
});

const mapDispatchToProps = (dispatch) => ({
    get_all_teams: (activeOuid) => dispatch(get_all_teams(activeOuid)),
    get_all_teamuser: (ouid, teamId) => dispatch(get_all_teamuser(ouid, teamId)),
    get_all_works: (ouid, type, teamId, status, username) => dispatch(get_all_works(ouid, type, teamId, status, username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamBoard);