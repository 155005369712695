import {
  CREATE_WORKORDER_REQUESTED,
  CREATE_WORKORDER_SUCCESS,
  CREATE_WORKORDER_ERRORED,
  GET_ALL_WORKORDERS_REQUESTED,
  GET_ALL_WORKORDERS_SUCCESS,
  GET_ALL_WORKORDERS_ERRORED,
  GET_WORKORDER_REQUESTED,
  GET_WORKORDER_SUCCESS,
  GET_WORKORDER_ERRORED,
} from '../constant/action-types';

const initialState = {
  showGlobalLoader: false,
  workorders: [],
  error: {}
};

export function workorder(state = initialState, { type, payload }) {
  switch (type) {

    case CREATE_WORKORDER_REQUESTED:
      return {
        ...state,
      };

    case CREATE_WORKORDER_SUCCESS:
      return {
        ...state,
      };

    case CREATE_WORKORDER_ERRORED:
      return {
        ...state,
        error: payload
      };

    case GET_ALL_WORKORDERS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        workorders: []
      };

    case GET_ALL_WORKORDERS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        workorders: payload.items
      };

    case GET_ALL_WORKORDERS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };

    case GET_WORKORDER_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
        booking: {}
      };

    case GET_WORKORDER_SUCCESS:
      console.log("GET__WORKORDER__SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
        booking: payload.items
      };

    case GET_WORKORDER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload
      };


    default:
      return state;
  }
}
