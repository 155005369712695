import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CommonSnackBar from "../../../common/CommonSnackBar";
import configureStore from "../../../../store/configureStore";
import FormErrors from "../../../FormErrors";
import FormButton from "../../../common/FormButton";
import ProgressBar from "../../../common/Progressbar";
import { showSnackbar } from "../../../../actions/action-snackbar";

import { create_update_work } from "./action-ticket";
import {
  document_upload_requested,
  create_document,
  document_upload_success,
  document_upload_errored,
} from "../../../../actions/action-document";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import axios from "axios";

const store = configureStore();

function CreateTicket(props) {
  const {
    usersToshow,
    activeOuid,
    activeOuidChain,
    workType,
    rerender,
    defaultState,
    handleClose,
  } = props;

  const [state, setState] = React.useState({ workDetails: {} });
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [uploadFileName, setUploadUploadFileName] = useState("");
  const [uploadFilesStatus, setUploadUploadFilesStatus] = useState("");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(defaultState);
  }, [defaultState]);

  const handleSubmit = async (event) => {
    const path = window.location.pathname.split("/");
    const ouid = path[1];
    const payload = state.workDetails;
    payload["type"] = workType;
    const { create_update_work } = props;
    console.log("payload : ", payload);

    const workId = await create_update_work("", ouid, payload, "");
    console.log("workId : ", workId);

    if (workId) {
      if (fileInput.current.files) {
        await handleUpload(workId);
      }
      store.dispatch(showSnackbar("Ticket Created Successfully", "success"));

      handleClose();
      rerender();
    }
  };

  const onFileUpload = async (file, workId) => {
    console.log(`onFileUploadX`);
    await store.dispatch(document_upload_requested());
    console.log("showUploadLoader", store.getState().document.showUploadLoader);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    setUploadUploadFileName(file.name);
    if (apiEndpoint && credentials) {
      const fileName = file.name;
      const type = file.type;
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getUploadSignedUrl=true&file=${fileName}&contentType=${type}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", response);
          await uploadFile(response, file, workId);
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  const uploadFile = async (signedUrlData, file, workId) => {
    console.log(`uploadFileX`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const method = "put";
      const url = signedUrlData.data.uploadURL;
      const body = file;
      var axiosConfig = {
        onUploadProgress: function (progressEvent) {
          console.log("progressEvent loaded", progressEvent.loaded);
          console.log("progressEvent total ", progressEvent.total);

          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log("percentCompleted", percentCompleted);
          setUploadPercentage(percentCompleted);
        },
        headers: {
          "Content-type": file.type,
        },
      };

      return axios[method](url, body, axiosConfig)
        .then(async (response) => {
          console.log("s3 upload response cc : ", response);
          var payload = {
            name: file.name,
            title: file.name,
            key: signedUrlData.data.Key,
            workId: workId,
          };
          console.log("documet payload: ", payload);

          await store.dispatch(document_upload_success());
          await store.dispatch(create_document(payload));

          return;
        })
        .catch((err) => {
          store.dispatch(document_upload_errored());
          console.log("s3 upload failed : ", err);
        });
    }
  };

  const onInputChange = (event) => {
    const newworkDetails = state.workDetails;
    if (event.target.id) {
      newworkDetails[event.target.id] = event.target.value;
    } else {
      newworkDetails[event.target.name] = event.target.value;
    }
    setState({
      workDetails: newworkDetails,
    });
  };

  const handleUpload = async (workId) => {
    setUploadPercentage(0);
    let newArr = fileInput.current.files;
    for (let i = 0; i < newArr.length; i++) {
      const summary = i + 1 + " of " + newArr.length;
      setUploadUploadFilesStatus(summary);
      await onFileUpload(newArr[i], workId);
    }
    setUploadUploadFileName("");
    setUploadUploadFilesStatus("");

    return setUploadPercentage(0);
  };

  const fileInput = React.useRef();
  const { showUploadLoader } = store.getState().document;
  const projectList = store.getState().tag.primaryTags;
  const sprintList = store.getState().tag.secondaryTags;

  const userChange = (newValue) => {
    console.log("newValue  newValue : ", newValue);
    if (newValue) {
      const newworkDetails = state.workDetails;
      newworkDetails["username"] = newValue.data.username;
      newworkDetails["userDisplayName"] = newValue.data.name;
      setState({
        workDetails: newworkDetails,
      });
    } else {
      const newworkDetails = state.workDetails;
      newworkDetails["username"] = "";
      setState({
        workDetails: newworkDetails,
      });
    }
  };

  const projectChange = (newValue) => {
    console.log("newValue  for [project] : ", newValue);
    console.log(
      "newValue  for [project].data.identifier : ",
      newValue.data.identifier
    );

    if (newValue.data.identifier) {
      const newworkDetails = state.workDetails;
      newworkDetails["primaryTag"] = newValue.data.identifier;
      setState({
        workDetails: newworkDetails,
      });
    } else {
      const newworkDetails = state.workDetails;
      newworkDetails["primaryTag"] = "";
      setState({
        workDetails: newworkDetails,
      });
    }
  };

  const sprintChange = (newValue) => {
    console.log("newValue  for [sprint] : ", newValue);

    if (newValue.data.identifier) {
      const newworkDetails = state.workDetails;
      newworkDetails["secondaryTag"] = newValue.data.identifier;
      setState({
        workDetails: newworkDetails,
      });
    } else {
      const newworkDetails = state.workDetails;
      newworkDetails["secondaryTag"] = "";
      setState({
        workDetails: newworkDetails,
      });
    }
  };

  console.log("projectList", projectList);

  return (
    <div>
      <div className="formContainer">
        <FormErrors formerrors={state.errors} />
        <form>
          <h2 className="formName">New ticket</h2>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="title"
              label="Title"
              placeholder="Title"
              value={state.workDetails ? state.workDetails.title : ""}
              onChange={onInputChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="form-item-wrapper">
            <TextField
              className="input"
              id="detail"
              label="Detail"
              rows={6}
              placeholder="Detail"
              multiline
              value={state.workDetails ? state.workDetails.detail : ""}
              onChange={onInputChange}
              variant="outlined"
              fullWidth
            />
          </div>
          {projectList.length !== 0 ? (
            <div className="form-item-wrapper">
              <Autocomplete
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  projectChange(newValue);
                }}
                options={projectList}
                getOptionLabel={(option) => option.data.identifier}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Project"
                    variant="outlined"
                  />
                )}
              />
            </div>
          ) : (
            "No Projects Found....."
          )}
          {sprintList.length !== 0 ? (
            <div className="form-item-wrapper">
              <Autocomplete
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  sprintChange(newValue);
                }}
                options={sprintList}
                getOptionLabel={(option) => option.data.identifier}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Sprint"
                    variant="outlined"
                  />
                )}
              />
            </div>
          ) : (
            "No Sprints Found....."
          )}
          <div className="form-item-wrapper">
            <TextField
              id="dueDate"
              label="Due Date"
              type="date"
              className="input"
              value={state.workDetails ? state.workDetails.dueDate : ""}
              onChange={onInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="form-item-wrapper">
            <InputLabel id="label">Priority</InputLabel>
            <Select
              labelId="label"
              fullWidth
              variant="outlined"
              id="priority"
              name="priority"
              value={state.workDetails ? state.workDetails.priority || "" : ""}
              onChange={onInputChange}
            >
              <MenuItem value="P1">P1</MenuItem>
              <MenuItem value="P2">P2</MenuItem>
              <MenuItem value="P3">P3</MenuItem>
              <MenuItem value="P4">P4</MenuItem>
            </Select>
          </div>
          <div className="form-item-wrapper Document-form-item-wrapper ">
            <h3>Document</h3>
            <input type="file" multiple ref={fileInput} />
            {showUploadLoader ? <div>{uploadFilesStatus}</div> : ""}

            {showUploadLoader ? <div>{uploadFileName}</div> : ""}
            {showUploadLoader ? (
              <ProgressBar bgcolor={"green"} completed={uploadPercentage} />
            ) : (
              ""
            )}
          </div>
          {usersToshow.length !== 0 ? (
            <div className="form-item-wrapper">
              <Autocomplete
                id="combo-box-demo"
                // value={state.selectedCp}
                onChange={(event, newValue) => {
                  userChange(newValue);
                }}
                options={usersToshow}
                getOptionLabel={(option) => option.data.name}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User"
                    variant="outlined"
                  />
                )}
              />
            </div>
          ) : (
            "No Users Found....."
          )}
        </form>
        <div className="field">
          <p className="control">
            <FormButton onClick={() => handleSubmit()} text="Add Ticket" />
          </p>
        </div>
        <CommonSnackBar />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  create_update_work: (workid, ouid, payload, type) =>
    dispatch(create_update_work(workid, ouid, payload, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTicket);
