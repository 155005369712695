import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../store/configureStore';
import { get_profile_details } from "../../actions/action-myProfile";
import MyProfileComponet from '../common/MyProfileComponet';
import Loader from '../common/Loader';
import { history } from '../../reducers';
import Sidebar from '../layout/Sidebar';

const store = configureStore();

class MyProfile extends Component {

    async componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            history.push("/login?redirect=/my-profile");
            return
        }
        const { get_profile_details } = this.props;
        let { session } = store.getState().jwt.api.userSession;
        await get_profile_details(session.username);
    }

    render() {
        const { details, showGlobalLoader } = store.getState().myProfile;
        const { permissions } = store.getState().permission;
        return (
            <div className="page">
                <Sidebar 
            auth={this.props.auth}
            permissions={permissions}
            />
            <section >
                {showGlobalLoader ?
                    <Loader />
                    : <MyProfileComponet
                        profileDetails={details.data}
                    />
                }
            </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    jwt: state.jwt,
    myProfile: state.myProfile,
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    get_profile_details: (username) => dispatch(get_profile_details(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
