import {
    GET_ALL_TEAMS_REQUESTED,
    GET_ALL_TEAMS_SUCCESS,
    GET_ALL_TEAMS_ERRORED,
    REGISTER_TEAM_REQUESTED,
    REGISTER_TEAM_SUCCESS,
    REGISTER_TEAM_ERRORED,
    GET_TEAM_DETAILS_REQUESTED,
    GET_TEAM_DETAILS_SUCCESS,
    GET_TEAM_DETAILS_ERRORED,
    UPDATE_TEAM_DETAILS_REQUESTED,
    UPDATE_TEAM_DETAILS_SUCCESS,
    UPDATE_TEAM_DETAILS_ERRORED,
  } from '../constant/action-types';
  
  const defaultState = {
    showGlobalLoader: false,
    teams: [],
    teamDetails: {},
    error: {}
  };
  
  export function team(state = defaultState, { type, payload }) {
    switch (type) {
        case REGISTER_TEAM_REQUESTED:
            console.log("REGISTER_TEAM_REQUESTED : ");
            return {
              ...state,
              showGlobalLoader: true,
            };
      
          case REGISTER_TEAM_SUCCESS:
            return {
              ...state,
              showGlobalLoader: false,
            };
      
          case REGISTER_TEAM_ERRORED:
            return {
              ...state,
              showGlobalLoader: false,
              error: payload
            };
      case GET_ALL_TEAMS_REQUESTED:
        // console.log("GET_ALL_TEAMS_REQUESTED : ");
        return {
          ...state,
          showGlobalLoader: true,
          teams: []
        };
  
      case GET_ALL_TEAMS_SUCCESS:
        // console.log("GET_ALL_TEAMS_SUCCESS : ", JSON.stringify(payload));
        return {
          ...state,
          showGlobalLoader: false,
          teams: payload.items
        };
  
      case GET_ALL_TEAMS_ERRORED:
        return {
          ...state,
          showGlobalLoader: false,
          error: payload
        };

        case GET_TEAM_DETAILS_REQUESTED:
            // console.log("GET_TEAM_DETAILS_REQUESTED : ");
            return {
              ...state,
              showGlobalLoader: true,
              teamDetails: {}
            };
      
          case GET_TEAM_DETAILS_SUCCESS:
            // console.log("GET_TEAM_DETAILS_SUCCESS : ", JSON.stringify(payload));
            return {
              ...state,
              showGlobalLoader: false,
              teamDetails: payload
            };
      
          case GET_TEAM_DETAILS_ERRORED:
            return {
              ...state,
              showGlobalLoader: false,
              error: payload
            };
            
            case UPDATE_TEAM_DETAILS_REQUESTED:
                // console.log("UPDATE_TEAM_DETAILS_REQUESTED : ");
                return {
                  ...state,
                  showGlobalLoader: true,
                };
          
              case UPDATE_TEAM_DETAILS_SUCCESS:
                // console.log("UPDATE_Orgunit_DETAILS_SUCCESS : ", JSON.stringify(payload));
                return {
                  ...state,
                  showGlobalLoader: false,
                };
          
              case UPDATE_TEAM_DETAILS_ERRORED:
                return {
                  ...state,
                  showGlobalLoader: false,
                  error: payload
                };
  
      default:
        return state;
    }
  }