import {
  CREATE_COURSE_REQUESTED,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_ERRORED,
  UPDATE_COURSE_REQUESTED,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_ERRORED,
  GET_COURSES_REQUESTED,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERRORED,
  GET_COURSE_REQUESTED,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERRORED,
  DELETE_COURSE_REQUESTED,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_ERRORED,
  CREATE_OR_UPDATE_COURSELINE_REQUESTED,
  CREATE_OR_UPDATE_COURSELINE_SUCCESS,
  CREATE_OR_UPDATE_COURSELINE_ERRORED,
  GET_COURSELINES_REQUESTED,
  GET_COURSELINES_SUCCESS,
  GET_COURSELINES_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  courses: [],
  coursesDetails: {},
  showGlobalLoaderCourseline: false,
  courselines: [],
  courselineDetails: {},
  error: {},
};

export function course(state = defaultState, action) {
  switch (action.type) {
    case GET_COURSES_REQUESTED:
      console.log("GET_COURSES_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_COURSES_SUCCESS:
      console.log("GET_COURSES_SUCCESS : ", JSON.stringify(action.payload));
      return {
        ...state,
        showGlobalLoader: false,
        courses: action.payload.items,
      };

    case GET_COURSES_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    case CREATE_COURSE_REQUESTED:
      console.log("CREATE_COURSE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_COURSE_SUCCESS:
      console.log("CREATE_COURSE_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_COURSE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case UPDATE_COURSE_REQUESTED:
      console.log("UPDATE_COURSE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_COURSE_SUCCESS:
      console.log("UPDATE_COURSE_SUCCESS : ");
      return {
        ...state,
        showGlobalLoader: false,
      };

    case UPDATE_COURSE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case GET_COURSE_REQUESTED:
      console.log("GET_COURSE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoader: true,
        courseDetails: {},
      };

    case GET_COURSE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        courseDetails: action.payload,
      };

    case GET_COURSE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.error,
      };

    case DELETE_COURSE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_COURSE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: action.payload,
      };

    case CREATE_OR_UPDATE_COURSELINE_REQUESTED:
      console.log("CREATE_OR_UPDATE_COURSELINE_REQUESTED : ");
      return {
        ...state,
        showGlobalLoaderCourseline: true,
      };

    case CREATE_OR_UPDATE_COURSELINE_SUCCESS:
      console.log("CREATE_OR_UPDATE_COURSELINE_SUCCESS : ");
      return {
        ...state,
        showGlobalLoaderCourseline: false,
      };

    case CREATE_OR_UPDATE_COURSELINE_ERRORED:
      return {
        ...state,
        showGlobalLoaderCourseline: false,
        error: action.error,
      };

    case GET_COURSELINES_REQUESTED:
      return {
        ...state,
        showGlobalLoaderCourseline: true,
        courselines: [],
      };

    case GET_COURSELINES_SUCCESS:
      return {
        ...state,
        showGlobalLoaderCourseline: false,
        courselines: action.payload.items,
      };

    case GET_COURSELINES_ERRORED:
      return {
        ...state,
        showGlobalLoaderCourseline: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}
