import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonSnackBar from "../../common/CommonSnackBar";
import { register_Orgunit } from "../../../actions/action-orgunit";
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import configureStore from '../../../store/configureStore';
import FormButton from '../../common/FormButton';
import {Paper, Box} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { showSnackbar, hideSnackbar } from '../../../actions/action-snackbar';

const store = configureStore();


class RegisterOrgunit extends Component {

  constructor() {
    super();
    this.state = {
      name: "",
      description: ""
    };
  }

  componentDidMount() {
    console.log("componentDidMount  : ");
    if (!this.props.auth.isAuthenticated) {
      const path = window.location.pathname;
      history.push(`/login?redirect=${path}`);
      return
    }
  }

  componentWillUnmount() {
    // store.dispatch(hideSnackbar());
}

  handleSubmit = async event => {
    // console.log("State  : ", this.state);
    const { register_Orgunit } = this.props;
    const { activeOuid, activeOuidChain } = store.getState().permission;
    if(activeOuid && activeOuidChain){
      await register_Orgunit(this.state, activeOuid, activeOuidChain);
      history.push(`/${activeOuid}/orgunits`);
    }else{
      store.dispatch(showSnackbar('No activeOuid or activeOuidChain', 'error'));
    }
    
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }


  render() {
    const { permissions } = store.getState().permission;
    return (
      <div className="page">
        <Sidebar
          auth={this.props.auth}
          permissions={permissions}
        />
        <section className="section">
          <div className="container">
              <Paper component="form" >
                <Box px={3} py={2}>
                <h1>Create sub orgunit</h1>
                <div className="form-item-wrapper">
                  <TextField
                    className="FormInput"
                    id="name"
                    placeholder="Name" 
                    variant="standard"
                    onChange={this.onInputChange}
                    helperText="Type organisation name"
                  />
                </div>
                <div className="form-item-wrapper">
                  <TextField
                    className="FormInput"
                    id="description"
                    placeholder="Description" 
                    variant="standard"
                    onChange={this.onInputChange}
                    helperText="Give a discription for the organisation"
                  />
                </div>
                <div className="form-item-wrapper">
                  <p className="control">
                    <FormButton
                      onClick={() => this.handleSubmit()}
                      text="Register"
                    />
                  </p>
                </div>
                </Box>
              </Paper>
              <CommonSnackBar />
          </div>
        </section>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  register_Orgunit: (payload, activeOuid, activeOuidChain) => dispatch(register_Orgunit(payload, activeOuid, activeOuidChain)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterOrgunit);
