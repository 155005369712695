import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { create_user_profile } from "../../actions/action-userProfiles";


class Register extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    const { name, username, email, phone_number, password } = this.state;
    try {
      const signUpResponse = await Auth.signUp({
        username,
        password,
        attributes: {
          email: email,
          phone_number: phone_number,
          name : name
        }
      });
      // console.log(signUpResponse);
      let userProfile = this.state;
      delete userProfile["password"]; 
      delete userProfile["confirmpassword"];
      delete userProfile["errors"];
      console.log("userProfile :", userProfile);
      // const { create_user_profile } = this.props;
      // await create_user_profile(userProfile);
      this.props.history.push("/welcome");
    } catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
      <div className="fullPage">
        
        <div className="fullPageform">
          <h1 className="loginHeading">Sign Up</h1>
          <FormErrors formerrors={this.state.errors} />

          <form onSubmit={this.handleSubmit}>
          <div className="field">
              <p className="control">
                <TextField 
                  className="input"
                  variant="outlined"
                  label="Name"
                  type="text"
                  id="name"
                  aria-describedby="userNameHelp"
                  placeholder="Enter name"
                  value={this.state.name}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control">
                <TextField 
                  className="input"
                  variant="outlined"
                  label="User name"
                  type="text"
                  id="username"
                  aria-describedby="userNameHelp"
                  placeholder="Enter username"
                  value={this.state.username}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <TextField 
                  className="input"
                  label="Email"
                  variant="outlined"
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <TextField 
                  className="input"
                  variant="outlined"
                  label="Phone Number"
                  type="text"
                  id="phone_number"
                  aria-describedby="userNameHelp"
                  placeholder="Enter phone number"
                  value={this.state.phone_number}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <TextField 
                  className="input"
                  label="Password"
                  variant="outlined"
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <TextField 
                  className="input"
                  label="Confirm password"
                  variant="outlined"
                  type="password"
                  id="confirmpassword"
                  placeholder="Confirm password"
                  value={this.state.confirmpassword}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <Button variant="contained" color="primary" type="submit">
                  Register
                </Button>
              </p>
            </div>
          </form>
          <div className="field">
              <p className="control">
                Registered User? <a href="/login">Login</a>
              </p>
          </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});


const mapDispatchToProps = (dispatch) => ({
  create_user_profile: (payload) => dispatch(create_user_profile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);