import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configureStore from "../../store/configureStore";
import {
  document_upload_requested,
  document_upload_errored,
  create_document,
  document_upload_success,
} from "../../actions/action-document";
import TextField from "@material-ui/core/TextField";
import Loader from "./Loader";
import ProgressBar from "./Progressbar";
import { showSnackbar } from "../../actions/action-snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import axios from "axios";

const store = configureStore();

const useStyles = makeStyles({
  Input: {
    fontSize: 14,
  },
  DialogText: {
    fontSize: 16,
  },
  CommentInputWrapper: {
    borderColor: "#dc281e",
    "& .MuiInput-underline": {
      "&:after": {
        borderColor: "#3b4f5a",
      },
    },
  },
  PrimaryButtonroot: {
    background: "#DC281E",
    padding: "5px 15px",
    fontSize: 12,
    "&:hover": {
      background: "#DC281E",
    },
  },
  SecondaryButtonroot: {
    borderColor: "#DC281E",
    marginRight: 15,
    fontSize: 12,
    padding: "5px 15px",
    color: "#DC281E",
    "&:hover": {
      borderColor: "#DC281E",
    },
  },
});

function DocumentUploader(props) {
  const { rerender, activeOuid, activeOuidChain } = props;
  const [file, setFile] = useState();
  const [details, setDetails] = useState({
    title: "",
  });

  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [uploadFileName, setUploadUploadFileName] = useState("");
  const [uploadFilesStatus, setUploadUploadFilesStatus] = useState("");

  const classes = useStyles();
  const { defaultState, handleClose } = props;
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(defaultState);
  }, [defaultState]);
  const handlePopUpClose = () => {
    setOpen(false);
    return handleClose();
  };

  const handleClick = async (event) => {
    event.preventDefault();
    setUploadPercentage(0);
    let newArr = fileInput.current.files;
    for (let i = 0; i < newArr.length; i++) {
      const summary = i + 1 + " of " + newArr.length;
      setUploadUploadFilesStatus(summary);
      await onFileUpload(newArr[i]);
    }
    handleClose();
    rerender();
    setUploadUploadFileName("");
    setUploadUploadFilesStatus("");

    return setUploadPercentage(0);
  };

  const onFileUpload = async (file) => {
    console.log(`onFileUpload`);
    await store.dispatch(document_upload_requested());
    console.log("showUploadLoader", store.getState().document.showUploadLoader);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    setUploadUploadFileName(file.name);
    if (apiEndpoint && credentials) {
      const fileName = file.name;
      const type = file.type;
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${apiEndpoint}/document?getUploadSignedUrl=true&file=${fileName}&contentType=${type}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", response);
          await uploadFile(response, file);
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  const uploadFile = async (signedUrlData, file) => {
    console.log(`uploadFile`);
    const { apiEndpoint, credentials } = store.getState().jwt.api;
    if (apiEndpoint && credentials) {
      const method = "put";
      const url = signedUrlData.data.uploadURL;
      const body = file;
      var axiosConfig = {
        onUploadProgress: function (progressEvent) {
          console.log("progressEvent loaded", progressEvent.loaded);
          console.log("progressEvent total ", progressEvent.total);

          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log("percentCompleted", percentCompleted);
          setUploadPercentage(percentCompleted);
        },
        headers: {
          "Content-type": file.type,
        },
      };

      return axios[method](url, body, axiosConfig)
        .then(async (response) => {
          console.log("s3 upload response cc : ", response);
          var payload = {
            name: file.name,
            title: details.title,
            key: signedUrlData.data.Key,
          };

          store.dispatch(
            showSnackbar("Document uploaded Successfully", "success")
          );
          await store.dispatch(document_upload_success());
          await store.dispatch(create_document(payload));

          return;
        })
        .catch((err) => {
          store.dispatch(document_upload_errored());
          console.log("s3 upload failed : ", err);
        });
    }
  };

  const { showUploadLoader } = store.getState().document;
  const fileInput = React.useRef();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <DialogContentText className={classes.DialogText}>
            <div>
              <h3>File Upload</h3>
              <div className="form-item-wrapper">
                <input type="file" multiple ref={fileInput} />
              </div>
              <div>
                <button onClick={handleClick}>Upload</button>
              </div>
              {showUploadLoader ? <div>{uploadFilesStatus}</div> : ""}

              {showUploadLoader ? <div>{uploadFileName}</div> : ""}
              {showUploadLoader ? (
                <ProgressBar bgcolor={"green"} completed={uploadPercentage} />
              ) : (
                ""
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlePopUpClose}
            color="primary"
            variant="outlined"
            classes={{
              root: classes.SecondaryButtonroot,
              label: classes.SecondaryButtonrlabel,
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  document: state.document,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploader);
