import React, { Component } from 'react';
import { connect } from 'react-redux';
import configureStore from '../../../store/configureStore';
import { get_org_user_details } from '../../../actions/action-orguser';
import { get_all_Orgunit } from '../../../actions/action-orgunit';
import { get_all_app_user } from '../../../actions/action-appuser';
import Loader from '../../common/Loader';
import { history } from '../../../reducers';
import Sidebar from '../../layout/Sidebar';
import MenuItem from '@material-ui/core/MenuItem';
import PolicyCard from '../../common/PolicyCard';
import CommonDelete from '../../common/CommonDelete';
import { timeFormat } from '../../common/CommonFunctions'
import { setActiveOuid } from "../../../actions/action-permissions";
import AppPolicyCard from '../../common/AppPolicyCard';



const store = configureStore();

class OrgUserDetails extends Component {

    constructor() {
        super();
        this.state = {
            userDetails: {},
            orgUnitType: '',
            orgUnitOuid: '',
            orgUnitRole: '',
            orgUnitsToShow: [],
            policies: [],
            errors: '',
            appUserProfiles: []
        };
    }

    componentDidMount() {
        console.log("componentDidMount");
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        const { activeOuid } = store.getState().permission;
        if (activeOuid) {
            this.fetchDetails(activeOuid);
        } else {
            this.fetchDetailsBasedOnUrl();
        }

    }

    componentDidUpdate() {
        console.log("componentDidUpdate");
    }

    async fetchDetails(activeOuid) {
        console.log("fetchDetails : ", activeOuid);
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        const { get_org_user_details } = this.props;
        await get_org_user_details(username, activeOuid);
        const { get_all_app_user } = this.props;
        await get_all_app_user(username, activeOuid);
        const { orgUserDetails } = store.getState().orgUserProfile;
        const { appUserProfiles } = store.getState().appUserProfile;
        this.setState({
            userDetails: orgUserDetails,
            roles: orgUserDetails.data.roles,
            appUserProfiles: appUserProfiles
        });
    }

    async fetchDetailsBasedOnUrl() {
        console.log("fetchDetailsBasedOnUrl");
        const path = window.location.pathname.split('/');
        const urlOuid = path[path.length - 3];
        console.log("urlOuid : ", urlOuid);
        const username = path[path.length - 1];
        const { get_org_user_details } = this.props;
        await get_org_user_details(username, urlOuid);
        const { get_all_app_user } = this.props;
        await get_all_app_user(username, urlOuid);
        const { orgUserDetails } = store.getState().orgUserProfile;
        const { appUserProfiles } = store.getState().appUserProfile;
        this.setState({
            userDetails: orgUserDetails,
            roles: orgUserDetails.data.roles,
            appUserProfiles: appUserProfiles
        }, () => {
            store.dispatch(setActiveOuid(urlOuid));
        });
    }

    renderDetails(details) {
        return Object.keys(details).map(function (keyName, keyIndex) {
            return (
                <div key={keyName} className="detailsItem">
                    <div className="detailsKey">
                        {keyName} : {' '}
                    </div>
                    {keyName === 'lastModifiedDate' ?
                        <div className="detailsValue">
                            {timeFormat(details[keyName])}
                        </div>
                        :
                        <div className="detailsValue">
                            {details[keyName]}
                        </div>
                    }

                </div>
            )
        });
    }

    renderMenuItem(orgUnitsToShow) {
        return orgUnitsToShow.map((item, index) => (
            <MenuItem key={item.data.identifier} value={item.data.identifier}>{item.data.name}</MenuItem>
        ));
    }

    handleSubmit = async event => {
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        let userDetails = this.state.userDetails;
        let newPolicy = [];
        if (userDetails.data.policies) {
            newPolicy = userDetails.data.policies;
            const attachingPolicy = this.state.orgUnitType + "_" + this.state.orgUnitOuid + "=" + this.state.orgUnitRole;
            if (newPolicy.includes(attachingPolicy)) {
                console.log("Policy Already Exists");
                this.setState({
                    errors: "Policy Already Exists"
                });
                return;
            }
            newPolicy.push(attachingPolicy);
        } else {
            const attachingPolicy = this.state.orgUnitType + "_" + this.state.orgUnitOuid + "=" + this.state.orgUnitRole;
            newPolicy.push(attachingPolicy);
        }
        userDetails.data["policies"] = newPolicy;
        const { update_user_profile } = this.props;
        await update_user_profile(userDetails, username);
    }

    renderApplicationPolicy() {
        const appUserProfiles = this.state.appUserProfiles;
        return appUserProfiles.map((item, index) => (
            <AppPolicyCard
                context={"detailsPage"}
                policy={item}
                index={index}
                deletePolicy={(index) => this.deleteAppRole(index)}
            />
        ));
    }

    render() {
        const { showGlobalLoader } = store.getState().orgUserProfile;
        const { permissions } = store.getState().permission;
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        return (
            <div className="page">
                <Sidebar
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    {showGlobalLoader ?
                        <Loader />
                        : <div className="container">
                            <h1>Org User Details</h1>
                            <CommonDelete
                                id={username}
                                type="userProfile"
                            />
                            {this.state.userDetails.data ?
                                <div >
                                    {this.renderDetails(this.state.userDetails.data)}
                                    {this.state.roles && (
                                        <div>
                                            {this.state.roles.map((item, index) => (
                                                <PolicyCard key={index}
                                                    policy={item}
                                                    index={index}
                                                    deletePolicy={(index) => this.deletePolicy(index)}
                                                />
                                            ))
                                            }
                                        </div>
                                    )}
                                    {this.state.appUserProfiles ?
                                        this.renderApplicationPolicy()
                                        :
                                        <></>
                                    }
                                </div>

                                : 'No user details Found'}
                        </div>}
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orgUserProfile: state.orgUserProfile,
    appUserProfile: state.appUserProfile,
    permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
    get_org_user_details: (username, activeOuid) => dispatch(get_org_user_details(username, activeOuid)),
    get_all_app_user: (username, activeOuid) => dispatch(get_all_app_user(username, activeOuid)),
    get_all_Orgunit: () => dispatch(get_all_Orgunit()),

});

export default connect(mapStateToProps, mapDispatchToProps)(OrgUserDetails);

